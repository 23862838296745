import { useDroppable } from "@dnd-kit/core";
import { QuizPlayerCard } from "../../QuizPlayer/Components/QuizPlayerCard";
import { QuizTeamCardProps } from "../Props/QuizTeamCardProps";
import { UNASSIGNED_TEAM_ID } from "../../Shared/Constants/UnassignedTeamId";
import { QuizPlayer } from "../../../interfaces/QuizPlayer/QuizPlayer";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useState } from "react";
import DeleteModal from "../../Shared/Delete/DeleteModal";

const QuizTeam: React.FC<QuizTeamCardProps> = ({ quizTeam, onQuizPlayerDelete, onQuizTeamDelete }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { isOver, setNodeRef } = useDroppable({ id: quizTeam.id });
  const isUnassignedTeam = quizTeam.id === UNASSIGNED_TEAM_ID;

  const onDeletePlayer = (quizPlayer: QuizPlayer) => {
    onQuizPlayerDelete(quizPlayer.id, quizTeam.id);
  };

  const handleOnDeleteButtonClick = () => {
    setShowConfirmationModal(true);
  }

  const handleOnQuizTeamDeleteCallback = () => {
    onQuizTeamDelete(quizTeam);
    setShowConfirmationModal(false);
  }

  return (
    <>
    <div
      ref={setNodeRef}
      className={`quiz-team-card 
                  ${isOver ? 'quiz-team-card-quiz-player-over' : ''} 
                  ${isUnassignedTeam ? 'quiz-team-card-unassigned' : 'quiz-team-card-assigned'}
                  ${quizTeam.teamId != null ? "quiz-team-card-existing" : "quiz-team-card-guest"}`}
    >
        <div className="quiz-team-header">
          <div className="title">
            {isUnassignedTeam ? "Niet toegewezen" : quizTeam.name}
          </div>

          {!isUnassignedTeam && (
            <div className="quiz-team-hover-controls">
              <IconButton aria-label="Delete" onClick={handleOnDeleteButtonClick}>
                <DeleteIcon />
              </IconButton>
            </div>
          )}
        </div>

      <div className="quiz-players">
        {quizTeam.quizPlayers.length > 0 ? (
          quizTeam.quizPlayers.map((quizPlayer) => (
            <QuizPlayerCard key={quizPlayer.id} quizPlayer={quizPlayer} onPlayerDelete={onDeletePlayer} isDraggable={true}/>
          ))
        ) : (
          <p>Geen Spelers</p>
        )}
      </div>
    </div>

    <DeleteModal
        deleteCallback={handleOnQuizTeamDeleteCallback}
        setOpenModal={setShowConfirmationModal}
        openModal={showConfirmationModal}
        subject='Team'
        subjectValue={quizTeam.name}/>
    </>
  );
};

export default QuizTeam;
