import { QuizPlayer } from "../../../interfaces/QuizPlayer/QuizPlayer";
import { QuizPlayerStatusses } from "../../../interfaces/QuizPlayer/QuizPlayerStatus";

export const getPlayerStatusClass = (quizPlayer: QuizPlayer) => {
    if (quizPlayer.guestName !== null) {
      return 'quiz-player-card-accepted';
    }

    if (quizPlayer.quizPlayerStatus === null) {
      return 'quiz-player-card-regular';
    }

    switch (quizPlayer.quizPlayerStatus) {
      case QuizPlayerStatusses.ACCEPTED:
        return 'quiz-player-card-accepted';
      case QuizPlayerStatusses.PENDING:
        return 'quiz-player-card-pending';
      case QuizPlayerStatusses.DECLINED:
        return 'quiz-player-card-declined';
      default:
        return 'quiz-player-card-regular';
    }
  };

  export const getPlayerRegisterdClass = (quizPlayer: QuizPlayer) => {
    if (quizPlayer.userId !== null) {
      return 'quiz-player-card-registered';
    }

    return 'quiz-player-card-unregistered'
  }