import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import '../Style/Leaderboard.scss'
import { LeaderBoardItem } from '../../../interfaces/Quiz/LeaderBoardItem';
import { LeaderboardShowOptions } from '../Enum/LeaderBoardShowOptions';
import { LeaderboardProps } from '../Props/LeaderboardProps';

const Leaderboard: React.FC<LeaderboardProps> = ({ fullLeaderBoard, totalPointsQuiz, ShowOptions }: LeaderboardProps) => {

  const [leaderBoardItems, setLeaderBoardItems] = useState<LeaderBoardItem[]>([]);
  const [currentLeaderBoardItemIndex, setcurrentLeaderBoardItemIndex] = useState<number>(-1);

  useEffect(() => {
    if (ShowOptions === LeaderboardShowOptions.All) {
      setLeaderBoardItems(fullLeaderBoard);
    } else {
      setcurrentLeaderBoardItemIndex(fullLeaderBoard.length - 1);
    }
  }, [ShowOptions, fullLeaderBoard]);

  const handleAddItem = () => {
    if (currentLeaderBoardItemIndex >= 0) {
      setLeaderBoardItems((prevItems) => [
        fullLeaderBoard[currentLeaderBoardItemIndex],
        ...prevItems
      ]);
      setcurrentLeaderBoardItemIndex(currentLeaderBoardItemIndex - 1);
    }
  };

  return (
    <div className="table-container">

      {
        (ShowOptions == LeaderboardShowOptions.StepByStep &&
          <Button
            variant="contained"
            onClick={handleAddItem}
            className="addTeamBtn"
            disabled={currentLeaderBoardItemIndex < 0}
          >
            Voeg volgend team toe
          </Button>)
      }

      <TableContainer component={Paper}>
        <Table className="table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="table-cell"></TableCell>
              <TableCell className="table-cell">Teamnaam</TableCell>
              <TableCell className="table-cell">Score</TableCell>
              <TableCell className="table-cell">Score (%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaderBoardItems.map((item, index) => (
              <TableRow key={item.rank}>
                <TableCell className={`${item.rank % 2 === 0 ? 'even-row' : 'odd-row'}`}>{item.rank}</TableCell>
                <TableCell className={`${item.rank % 2 === 0 ? 'even-row' : 'odd-row'}`}>{item.teamName}</TableCell>
                <TableCell className={`${item.rank % 2 === 0 ? 'even-row' : 'odd-row'}`}>{`${item.absoluteScore} / ${totalPointsQuiz}`}</TableCell>
                <TableCell className={`${item.rank % 2 === 0 ? 'even-row' : 'odd-row'}`}>{item.relativeScorePercentage.toFixed(2)}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Leaderboard;
