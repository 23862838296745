import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Question } from "../../../interfaces/Quiz/Question";
import { CreateOrEditQuestionPopupProps } from "../Properties/CreateOrEditQuestionPopupProps";
import { CreateOrEditQuestion } from "../../../interfaces/Questions/CreateOrEditQuestion";

const CreateOrEditQuestionPopup: React.FC<CreateOrEditQuestionPopupProps> = ({
    onQuestionChange,
    setShouldOpenPopup,
    selectedRound,
    shouldOpenPopup,
    initialQuestion
}) => {
    const [question, setQuestion] = useState<CreateOrEditQuestion>(initialQuestion);

    useEffect(() => {
        setQuestion(initialQuestion);
    }, [initialQuestion]);

    function handleClose() {
        setShouldOpenPopup(false);
    }

    const handleOnSequenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSequence = Number(event.target.value);
        setQuestion((prevQuestion) => ({
            ...prevQuestion,
            sequence: newSequence,
        }));
    };

    const handleOnNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        setQuestion((prevQuestion) => ({
            ...prevQuestion,
            description: newName,
        }));
    };


    const handleOnSubmit = (e: any) => {
        handleClose();
        
        let createOrUpdateQuestion: Question = {
            description: question.description,
            sequence: question.sequence,
            roundId: selectedRound.id,
            id: question.questionId
        }

        if(initialQuestion.isNewQuestion){
            createOrUpdateQuestion.id = null;
        }

        onQuestionChange(createOrUpdateQuestion);
    };

    const dialogTitle = () => {
        if (!question.isNewQuestion) {
            return `De vraag '${question.description}' aanpassen?`
        }
        else return `Een nieuwe vraag aanmaken voor ronde '${selectedRound.name}'`
    }

    return (
        <Dialog open={shouldOpenPopup} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>{dialogTitle()}</DialogTitle>
            <DialogContent>
                {question.isNewQuestion && (
                    <>
                        <TextField
                            margin="dense"
                            name="description"
                            label="Wat is je vraag?"
                            type="text"
                            fullWidth
                            value={question.description}
                            onChange={handleOnNameChange}
                            InputLabelProps={{ shrink: true, }}
                        />

                    </>
                )
                }
                <TextField
                    margin="dense"
                    name="sequence"
                    label="Volgorde"
                    type="number"
                    fullWidth
                    value={question.sequence}
                    onChange={handleOnSequenceChange}
                />


            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleOnSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditQuestionPopup;
