import QuestionListMobile from '../../Question/Components/QuestionList/QuestionListMobile';
import RoundPopup from './RoundPopup';
import { RoundsMobileScreenProps } from '../Properties/RoundsMobileScreenProps';
import { Button, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { CreateOrEditRound } from '../../../interfaces/Round/CreateOrEditRound';
import { Round } from '../../../interfaces/Quiz/Round';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const Rounds: React.FC<RoundsMobileScreenProps> = ({ quiz, selectedRound, shouldOpenPopup,
    onRoundChange, handelOnDeleteRound, handleRoundClick, onQuizChange, saveNewRound, defaultNewRound, setShouldOpenPopup }) => {

    const [roundDataProp, setRoundDataProp] = useState<CreateOrEditRound>();
    const auth = useAuth();

    useEffect(() => {
        let obj =
            {
                quizId: quiz.id,
                countOfQuestions: quiz.rounds[0].totalQuestions,
                isNewRound: false,
                kindOfRound: quiz.rounds[0].kindOfRound,
                maxPoints: quiz.rounds[0].maxPoints,
                roundName: quiz.rounds[0].name,
                roundId: quiz.rounds[0].id
            } as CreateOrEditRound
        setRoundDataProp(obj);
    }, [quiz, auth]);


    function onEditRoundClick(round: Round) {
        let newRoundDataProp: CreateOrEditRound = {
            quizId: quiz.id,
            countOfQuestions: round.totalQuestions,
            isNewRound: false,
            kindOfRound: round.kindOfRound,
            maxPoints: round.maxPoints,
            roundName: round.name,
            roundId: round.id
        };
        setRoundDataProp(newRoundDataProp);
        setShouldOpenPopup(true);
    }

    function onDeleteRoundClick(round: Round) {
        handelOnDeleteRound(round.id);
    }

    function onRoundSelectChange(event: SelectChangeEvent<number>) {
        let clickedRoundId = Number(event.target.value);
        let roundClicked = quiz.rounds.find(x => x.id === clickedRoundId);
        if (!roundClicked) return;
        handleRoundClick(roundClicked);
    }

    function onRoundPopupDataChange(roundData: CreateOrEditRound) {
        if (roundData.isNewRound) {
            saveNewRound(roundData)
        } else {
            onRoundChange(roundData);
        }
    }

    function onRoundAddClick() {
        let obj = defaultNewRound();
        setRoundDataProp(obj);
        setShouldOpenPopup(true);
    }

    return (
        <>
            <div id="round-container">
                <div>
                    <Button fullWidth variant="contained" startIcon={<AddIcon />} onClick={() => { onRoundAddClick() }} > Een ronde toevoegen
                    </Button>

                </div>
                <div>
                    <FormControl fullWidth id='selectRound'>
                        <InputLabel id="selectRound">Ronde</InputLabel>
                        <Select
                            labelId="selectRoundLabelId"
                            id="selectedRoundSelect"
                            value={selectedRound.id}
                            label="Ronde"
                            onChange={onRoundSelectChange}
                            renderValue={(selected) => {
                                const selectedRound = quiz.rounds.find(round => round.id === selected);
                                return (
                                    <MenuItem key={selectedRound.id} value={selectedRound.id}>
                                        <ListItemText primary={selectedRound.name} />
                                        <ListItemIcon>
                                            <DeleteIcon onClick={() => { onDeleteRoundClick(selectedRound) }} />
                                            <EditIcon onClick={() => { onEditRoundClick(selectedRound) }} />
                                        </ListItemIcon>
                                    </MenuItem>
                                );
                            }}
                        >
                            {quiz.rounds.map((round) => (
                                <MenuItem key={round.id} value={round.id}>
                                    <ListItemText primary={round.name} />
                                    <ListItemIcon>
                                        <DeleteIcon onClick={() => { onDeleteRoundClick(round) }} />
                                        <EditIcon onClick={() => { onEditRoundClick(round) }} />
                                    </ListItemIcon>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>

            <div id="question-list-container">
                <div className="question-column-content">
                    <QuestionListMobile
                        selectedRound={selectedRound}
                        onQuizChange={onQuizChange}
                        quiz={quiz}
                    />
                </div>
            </div>
            {
                roundDataProp &&
                <RoundPopup
                    roundDataProp={roundDataProp}
                    onRoundPopupDataChange={onRoundPopupDataChange}
                    shouldOpenPopup={shouldOpenPopup}
                    setShouldOpenPopup={setShouldOpenPopup}
                ></RoundPopup>
            }
        </>
    );
}

export default Rounds;