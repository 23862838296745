import { Quiz } from "../../../interfaces/Quiz/Quiz";
import { TeamRoundPoint } from "../../../interfaces/Quiz/TeamRoundPoint";

export function UpdateQuizWithNewTeamRoundPoints(quiz: Quiz, quizTeamId:number, roundId:number,updatedPoints: string): Quiz {
    
    const teamRoundPointsWithUpdatedPoints = quiz.teamRoundPoints.map((team: TeamRoundPoint) => {
        if (team.quizTeamId === quizTeamId && team.roundId === roundId) 
        {
            return { ...team, points: updatedPoints };
        }
        return team;
      });

      const updatedQuiz: Quiz = {
        ...quiz,
        teamRoundPoints: teamRoundPointsWithUpdatedPoints
      };
      
      return updatedQuiz;
}