import { useNavigate } from "react-router-dom";
import ConfirmationMessage from "./ConfirmationMessage"
import { useEffect, useState } from "react";
import { MailRepo } from "../Repo/MailRepo";
import { ConfirmationRegisterToSystemWithQuizProps } from "../Props/ConfirmationRegisterToSystemWithQuizProps";

const RegisterToSystemWithQuiz = ({confirmationKey, userId}: ConfirmationRegisterToSystemWithQuizProps) => {
    const navigate = useNavigate();
    const mailRepo = new MailRepo(); 
    const [isAttachingUserToQuiz, setIsAttachingUserToQuiz] = useState<Boolean>(true);

    useEffect(() => {
        mailRepo.RegisterToSystemWithQuiz(() => {
            setIsAttachingUserToQuiz(false);
        },
        confirmationKey, userId)
    }, [confirmationKey]);

    if(isAttachingUserToQuiz){
        return (
            <ConfirmationMessage
            title="Succesvol geregistreerd en Koppelen account aan quiz."
            message="Even geduld."
            buttonText={null}
            onButtonClick={null}
            />
        )
    }

    return (
        <ConfirmationMessage
        title="Succesvol geregistreerd en gekoppeld"
        message="Bedankt voor je registratie! 😊 We zijn blij dat je erbij bent."
        buttonText="Ga naar startpagina"
        onButtonClick={() => navigate(`/`)}
        />
    )
}

export default RegisterToSystemWithQuiz;