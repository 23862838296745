import { useEffect, useState } from 'react';
import { Card, CardContent, TextField, IconButton, Typography, Modal, Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { QuizRepo } from '../../../Quiz/Repo/QuizRepo';
import { useAuth } from 'react-oidc-context';
import { QuestionsFromRound } from '../../../../interfaces/Quiz/QuestionsFromRound';
import { CreateOrEditQuestion } from '../../../../interfaces/Questions/CreateOrEditQuestion';
import { UpdateQuestion } from '../../../../interfaces/Questions/UpdateQuestion';
import CreateOrEditQuestionPopup from '../CreateOrEditQuestionPopup';
import DeleteModal from '../../../Shared/Delete/DeleteModal';
import { SortableQuestionMobileProps } from '../../Properties/SortableQuestionMobileProps';
import FileUploader from '../../../Shared/Components/FileUpload/FileUploader';
import { FileUploadStatus } from '../../../Shared/Components/FileUpload/Enum/FileUploadStatus';
import { AttachmentSource } from '../../../Shared/Components/FileUpload/Enum/AttachmentSource';
import Answers from '../../../Answer/Components/Answers';

function SortableQuestionMobile({
  initialQuestion,
  quiz,
  descriptionRef,
  selectedRound,
  onDeleteQuestion,
  onQuestionChange,
  onAddAnswer,
  onDeleteAnswer,
  onUpdateAnswer,
  onAddUrlAttachment,
  onDeleteAttachment,
  onFileAttachmentUpload
}: SortableQuestionMobileProps) {

  const auth = useAuth();
  const quizRepo = new QuizRepo(auth);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showCreateOrEditQuestionPopup, setShowCreateOrEditQuestionPopup] = useState(false);

  const [question, setQuestion] = useState<QuestionsFromRound>(initialQuestion);
  const [questionInCreateOrEditPopup, setQuestionForCreateOrEditPopup] = useState<CreateOrEditQuestion>({} as CreateOrEditQuestion);

  useEffect(() => {
      setQuestion(initialQuestion);
  }, [quiz, questionInCreateOrEditPopup]);


  const handleSaveChanges = async () => {
    const updateQuestion: UpdateQuestion = {
      id: question.id,
      description: question.description ?? "",
      sequence: question.sequence ?? 0
    };

    try {
      await quizRepo.UpdateQuestion(() => { onQuestionChange(updateQuestion); }, updateQuestion);
    } catch (error) {
      console.error('Error saving question:', error);
    }
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDescription = event.target.value;
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      description: newDescription,
    }));
  };

  const onChangeAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAnswer = event.target.value;
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      answer: newAnswer,
    }));
  };

  const onBlurQuestionAnswer = () => {
    handleSaveChanges();
  };

  const onBlurQuestionDescription = () => {
    handleSaveChanges();
  };

  const showCreateOrEditQuestionPopupHandler = () => {

    let createOrEditQuestion: CreateOrEditQuestion = {
      isNewQuestion: false,
      sequence: question.sequence,
      description: question.description,
      questionId: question.id,
      roundName: selectedRound.name
    }

    setQuestionForCreateOrEditPopup(createOrEditQuestion);
    setShowCreateOrEditQuestionPopup(true);
  }

  const handleFileUpload = async (file: File): Promise<FileUploadStatus> => {
    return await onFileAttachmentUpload(file, question.id);
};

 const handleDeleteAttachment = async (attachmentId: number) => {
   onDeleteAttachment(attachmentId, question.id);
 }

 const handleAddUrlAttachment = async (url: string, source: AttachmentSource) =>
 {
   onAddUrlAttachment(url, source, question.id);
 }

 const handleOnDeleteAnswer = (answerId: number) => {
  onDeleteAnswer(answerId, question.id);
}

const handleOnUpdateAnswer = (answerId: number, description: string) => {
  onUpdateAnswer(question.id, description, answerId);
}

const handleOnAddAnswer = (): void => {
onAddAnswer(question.id);
}

  return (
    <>
      <div className="question-list-element">
        <Card variant="outlined" className="question-card">
          <CardContent className='question-card-content'>
            <div className="question-header">
              <div className="question-container-left">
                {question.sequence}
              </div>
              <div className="question-container-right">
                <IconButton
                  className={`question-container-button`}
                  aria-label="Delete"
                  onClick={() => setShowConfirmationModal(true)}
                >
                  <DeleteIcon />
                </IconButton>

                <IconButton
                  className={`question-container-button`}
                  aria-label="Edit"
                  onClick={() => showCreateOrEditQuestionPopupHandler()}
                >
                  <EditIcon />
                </IconButton>

              </div>
            </div>

            <TextField
                className='question-input-element'
                label="Vraag"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                value={question.description}
                onChange={handleDescriptionChange}
                onBlur={onBlurQuestionDescription}
                inputRef={descriptionRef}
              />

              <Answers answers={question.answers} 
                onAddNewAnswer={handleOnAddAnswer} 
                onDeleteAnswer={handleOnDeleteAnswer}
                onUpdateAnswer={handleOnUpdateAnswer}/>

                <FileUploader
                    showDragAndDrop={false}
                    showPreview={true}
                    showYouTubeInput={true}
                    attachments={question.attachments}
                    maxAttachments={3}
                    acceptedTypes={['image/jpeg', 'image/png', 'video/mp4', 'audio/mpeg', 'video/youtube']}
                    onFileUploaded={handleFileUpload}
                    onAddUrl={handleAddUrlAttachment}
                    onDeleteAttachment={handleDeleteAttachment}
                  />
          </CardContent>
        </Card>
      </div>


      <CreateOrEditQuestionPopup
        initialQuestion={questionInCreateOrEditPopup}
        shouldOpenPopup={showCreateOrEditQuestionPopup}
        selectedRound={selectedRound}
        onQuestionChange={onQuestionChange}
        setShouldOpenPopup={setShowCreateOrEditQuestionPopup}
      ></CreateOrEditQuestionPopup>

      <DeleteModal
        deleteCallback={() => onDeleteQuestion(question.id)}
        setOpenModal={setShowConfirmationModal}
        openModal={showConfirmationModal}
        subject='Vraag'
        subjectValue={question.description}/>
    </>
  );
}

export default SortableQuestionMobile;
