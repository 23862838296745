import { FC } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { DeleteModalProps } from './DeleteModalProps';

import './DeleteModal.scss';

const DeleteModal: FC<DeleteModalProps> = ({ openModal, setOpenModal, deleteCallback, subject, subjectValue}) => {
    return (
        <Modal open={openModal} onClose={() => setOpenModal(false)} className='deleteModal'>
            <Box className='deleteModalBox'>
                <div className='deleteModalContent'>
                    <Typography variant="h6" component="h2" className='deleteModalTitle'>
                        <p>Verwijderen <strong>{subject}</strong></p>
                    </Typography>
                    <Typography className='deleteModalText'>
                        Weet je zeker dat je {subject} <strong>{subjectValue}</strong> wilt verwijderen
                    </Typography>
                </div>
                <Box className='deleteModalActions'>
                    <Button variant="contained" className='deleteModalCancelButton' onClick={() => setOpenModal(false)}>
                        Annuleer
                    </Button>
                    <Button variant="contained" color="error" className='deleteModalDeleteButton' onClick={deleteCallback}>
                        Verwijderen
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default DeleteModal;

