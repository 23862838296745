import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import frontImage from "../../../images/NotebookWithQuestionMarks.jpg"
import { Button, CircularProgress } from '@mui/material';


const LoginComponent: FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    let createUserBaseUrl: string | undefined = process.env.REACT_APP_CreateUserBaseUrl;

    useEffect(() => {
    }, [auth, navigate])

    function onRegisterClicked() {
        const relativePath = '/account/registered';
        const returnUrl = new URL(relativePath, window.location.origin).href;
        let urlToCreateAccount = `${createUserBaseUrl}?ReturnUrl=${encodeURI(returnUrl)}`;
        window.location.href = urlToCreateAccount;
    }

    const handleLoginClick = async () => {
        setIsLoading(true);
        try {
            await auth.signinRedirect();
        } catch (error) {
            console.error('Login failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    const LoginForm = () => {
        if (!auth.isAuthenticated) {
            return <>
                <div id='loginContainer'>
                    <div className='quizTitleContainer'>
                        <h1>QuizLab Béta</h1> 
                        <div id="quoteContainer">
                            <div id="quote">The only true wisdom is in knowing you know nothing.</div>
                            <div id="quoter"> - Thijs Vandaele -</div>
                        </div>
                    </div>
                </div>

                <div id='loginAuthenticationContainer'>

                    <div id='loginAuthenticationImageContainer'>
                        <img src={frontImage}></img>
                    </div>
                    <div id='loginAuthenticationButtons'>
                        <Button variant="contained" onClick={onRegisterClicked}>Register</Button>
                        <Button variant="contained" onClick={handleLoginClick} disabled={isLoading}>
                            {isLoading ? <CircularProgress size={24} /> : 'Login'}
                        </Button>
                    </div>

                </div>
            </>

        }
    }

    return (
       <LoginForm></LoginForm>
    )
};

export default LoginComponent;
