import React, { useState, ChangeEvent, useEffect, FormEvent} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField, FormControlLabel, FormGroup, Button, Switch, Tooltip, } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { SaveQuizData } from '../../../interfaces/Quiz/SaveQuizData';
import { QuizRepo } from '../Repo/QuizRepo';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import EmailIcon from '@mui/icons-material/Email';
import { useAuth } from 'react-oidc-context';
import SendEmailQuizPlayersModal from './SendEmailQuizPlayersModal';
import { SendEmailInvites } from '../../../interfaces/Quiz/SendEmailInvites';
import SnackbarNotification from '../../Shared/Snackbar/SnackbarNotifcation';
import LeaderboardOptionsDialog from '../../Scores/Components/LeaderBoardOptionsDialog';
import { LeaderBoardItem } from '../../../interfaces/Quiz/LeaderBoardItem';
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { UNASSIGNED_TEAM_ID } from '../../Shared/Constants/UnassignedTeamId';
import ChangedDataDialog from '../../Shared/Components/DataChanged/Components/ChangedDataDialog';
import { areDatesEqual, getDateTimeString } from '../../Shared/Date/DateHelper';
import { UpdateQuizWithSentQuizPlayers } from '../Shared/QuizStateHelper';
import AddressComponent from '../../Shared/Components/Address/Componenets/AddressComponent';
import { Address } from '../../Shared/Components/Address/Interface/Address';
import { ChangedDataFromOriginalItem } from '../../Shared/Components/DataChanged/Interface/ChangedDataItem';
import { ChangedDataFromOriginalActionButtonProps } from '../../Shared/Button/ChangedDataFromOriginalActionButtonProps';

interface QuizDataProps {
  quiz: Quiz;
  saveQuizData: (data: SaveQuizData) => Promise<void>;
  setUpdatedQuiz: (quiz: Quiz) => void;
  checkChangedQuizData: boolean;
}

const QuizData: React.FC<QuizDataProps> = ({ quiz, saveQuizData, setUpdatedQuiz, checkChangedQuizData }) => {
  const [quizName, setQuizName] = useState('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [publicationDate, setPublicationDate] = useState<Dayjs | null>(null);
  const [isPublished, setIsPublished] = useState(false);
  const [showInvitationModal, setShowInvitationModal] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [isLeaderboardModalOpen, setIsLeaderboardModalOpen] = useState<boolean>(false);
  const [address, setAddress] = useState<Address>(quiz.address);
  const originalQuizData = {...quiz} as Quiz;
  const [changedQuizData, setChangedQuizData] = useState<ChangedDataFromOriginalItem[]>();
  const [showChangedQuizDataDialog, setShowChangedQuizDataDialog] = useState<boolean>(false);

  const auth = useAuth();
  const quizRepo = new QuizRepo(auth);

  useEffect(() => {
    setQuizName(quiz.name);
    setIsPublished(quiz.isPublished);

    if (quiz.startDate != null) {
      setStartDate(dayjs(quiz.startDate));
      setPublicationDate(dayjs(quiz.publishDate));
    }
  }, [quiz, auth]);

  function sendInvitationMail(quizPlayerIds: number[]) {
    setShowInvitationModal(false);

    quizRepo.sendEmailInvites(
        () => {
            setSnackbarMessage("Invitaties zijn succesvol verstuurd");
            setShowSnackbar(true);

            const updatedQuiz = UpdateQuizWithSentQuizPlayers(quiz, quizPlayerIds);
            setUpdatedQuiz(updatedQuiz);
        },
        () => {},
        quiz.id,
        { QuizPlayerIds: quizPlayerIds } as SendEmailInvites
    );
}


  function getQuizPlayersWithEmail() {
    const quizPlayersWithEmail = quiz.quizTeams
        .flatMap(team => team.quizPlayers) 
        .filter(player =>
            player.userId !== null ||
            player.userMail !== null ||
            player.invitationMail !== null
        );

    return quizPlayersWithEmail;
}


  const handleOnStartDateChange = (date: Dayjs) => {
      setStartDate(date);
      const newPublicationDate = date.add(4, 'hours');
      setPublicationDate(newPublicationDate);
  };

  const handleOnPublicationDateChange = (date: Dayjs) => {
    setPublicationDate(date);
};

  const handleIsPublishedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPublished(event.target.checked);
  };

  const handleOnGeneratePowerpointClick = () => {
    const fileName = getDateTimeString(new Date()) + "_" + quizName + ".pptx";
    quizRepo.GeneratePowerpoint(() => {
    },
      quiz.id ?? 0, fileName)
  }

  const handleQuizDataOnSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const formData: SaveQuizData = {
      quizId: quiz.id,
      name: quizName,
      startDate: startDate ? setTimeZoneOffset(startDate.toDate()) : null,
      publishDate: publicationDate ? setTimeZoneOffset(publicationDate.toDate()) : null,
      isPublished: isPublished,
      address: {id: quiz.address.id, ...address}
    };

    await saveQuizData(formData);
    checkChangedData();
  };

  const checkChangedData = () =>  {
    if (!checkChangedQuizData) return;
    const changedData = getChangedData();

    if(changedData.length > 0){
      setChangedQuizData(changedData);
      setShowChangedQuizDataDialog(true);
    }
  };

  function getChangedData(): ChangedDataFromOriginalItem[] {
    const changedData = [] as ChangedDataFromOriginalItem[];

    originalQuizData.startDate = new Date(originalQuizData.startDate);
    if(!areDatesEqual(originalQuizData.startDate, startDate.toDate())) changedData.push({ propertyName: "start datum", originalValue: getDateTimeString(originalQuizData.startDate, "DDDD DD/MM/YYYY"), newValue: getDateTimeString(startDate.toDate(), "DDDD DD/MM/YYYY")} as ChangedDataFromOriginalItem);

    if(originalQuizData.address.street !== address.street) changedData.push({ propertyName: "straat", originalValue: originalQuizData.address.street, newValue: address.street} as ChangedDataFromOriginalItem);
    if(originalQuizData.address.number !== address.number) changedData.push({ propertyName: "nummer", originalValue: originalQuizData.address.number, newValue: address.number} as ChangedDataFromOriginalItem);
    if(originalQuizData.address.town !== address.town) changedData.push({ propertyName: "stad", originalValue: originalQuizData.address.town, newValue: address.town} as ChangedDataFromOriginalItem);
    if(originalQuizData.address.postalcode !== address.postalcode) changedData.push({ propertyName: "postcode", originalValue: originalQuizData.address.postalcode, newValue: address.postalcode} as ChangedDataFromOriginalItem);
    if(originalQuizData.address.province !== address.province) changedData.push({ propertyName: "provincie", originalValue: originalQuizData.address.province, newValue: address.province} as ChangedDataFromOriginalItem);
    if(originalQuizData.address.country !== address.country) changedData.push({ propertyName: "land", originalValue: originalQuizData.address.country, newValue: address.country} as ChangedDataFromOriginalItem);

    return changedData;
  }

  function calculateTotalPointsOfQuiz(quiz: Quiz) : number {
    return quiz.rounds.reduce((total, round) => {
        return total + round.maxPoints;
    }, 0);
  }

  function calculateLeaderboard(quiz: Quiz): LeaderBoardItem[] {
    const teamScores: { [key: number]: number } = {};

    for (const teamRoundPoint of quiz.teamRoundPoints) {
        if (!teamScores[teamRoundPoint.quizTeamId]) {
            teamScores[teamRoundPoint.quizTeamId] = 0;
        }
        teamScores[teamRoundPoint.quizTeamId] += parseFloat(teamRoundPoint.points);
    }
    
    const totalPointsOfQuiz = calculateTotalPointsOfQuiz(quiz)

    const leaderboard: LeaderBoardItem[] = [];

    for (const team of quiz.quizTeams.filter(quizTeam => quizTeam.id !== UNASSIGNED_TEAM_ID)) {
        const absoluteScore = teamScores[team.id] || 0;
        const relativeScorePercentage = totalPointsOfQuiz > 0 ? (absoluteScore / totalPointsOfQuiz) * 100 : 0;

        leaderboard.push({
            rank: 0, 
            teamName: team.name,
            absoluteScore,
            relativeScorePercentage
        });
    }
    leaderboard.sort((a, b) => b.absoluteScore - a.absoluteScore);

    leaderboard.forEach((item, index) => {
        item.rank = index + 1;
    });

    return leaderboard;
}

  function setTimeZoneOffset(date: Date, targetOffset = 0) {
    const currentOffset = date.getTimezoneOffset();
    const newDate = new Date(date);

    const offsetDifference = targetOffset - currentOffset;
    newDate.setMinutes(newDate.getMinutes() + offsetDifference);

    return newDate;
  }

  const handleLeaderboardClick = () => {
    setIsLeaderboardModalOpen(true); 
  };

  const handleCloseLeaderboardModal = () => {
    setIsLeaderboardModalOpen(false);
  };

  const handleCloseDataChangedDialog = () => {
    setShowChangedQuizDataDialog(false);
  }

  const handleSendMailsClick = () => {
    setShowInvitationModal(true);
  };

  const handleCloseInvitationModal = () => {
    setShowInvitationModal(false);
  };

  const handleAddressChange = (newAddress: Address) => {
    setAddress(newAddress);
  };

  const changedDataActions: ChangedDataFromOriginalActionButtonProps[] = [
    {
        buttonText: "Uitnodigingen hersturen",
        toolltipText: "De invitatie pagina wordt geopend",
        onButtonClick: () => {
            setShowInvitationModal(true);
            setShowChangedQuizDataDialog(false);
        },
        variant: "contained"
    },
    {
      buttonText: "sluiten",
      toolltipText: null,
      onButtonClick: () => {
          setShowChangedQuizDataDialog(false);
      },
      variant: "contained"
  }
];

  return (
    <>
      <form id="quiz-data-form" onSubmit={handleQuizDataOnSubmit}>
        <TextField
          label="Naam"
          variant="outlined"
          value={quizName}
          className='form-input'
          onChange={(e) => setQuizName(e.target.value)}
          required={true}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Startdatum"
            onChange={(date) => handleOnStartDateChange(date)}
            value={startDate}
            className='form-input'
            ampm={false}
            slotProps={{
              actionBar: {
                actions: ['clear']
              }
            }}
          />

          <DateTimePicker
            label="Publiceerdatum"
            onChange={(date) => handleOnPublicationDateChange(date)}
            value={publicationDate}
            className='form-input'
            ampm={false}
            slotProps={{
              actionBar: {
                actions: ['clear']
              }
            }}
          />
        </LocalizationProvider>

        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isPublished} onChange={handleIsPublishedChange} />}
            className='form-input'
            label="Gepubliceerd"
          />
        </FormGroup>

        <AddressComponent address={address} onAddressChange={handleAddressChange} showAddressAutocomplete={true} showAddressMap={true}/>

        {
          quiz.id != null &&
          (
            <div className="quiz-actions-button-list">
              <Tooltip title="Genereer PowerPoint" arrow>
                <Button variant="contained" onClick={handleOnGeneratePowerpointClick}>
                  <AutoAwesomeMotionIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Leaderboard">
                <Button variant="contained" onClick={handleLeaderboardClick} >
                  <EmojiEventsIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Verstuur Mails">
                <Button variant="contained" onClick={handleSendMailsClick}>
                  <EmailIcon />
                </Button>
              </Tooltip>
            </div>
          )
        }
        <Button variant="contained" startIcon={<SaveIcon />} type='submit'>
          Opslaan Quiz
        </Button>
      </form>


      <ChangedDataDialog open={showChangedQuizDataDialog} title='Er is praktische data gewijzigd.'
         buttonActionsInfo={changedDataActions} changedDataItems={changedQuizData}
         onClose={handleCloseDataChangedDialog}/>

      <LeaderboardOptionsDialog open={isLeaderboardModalOpen} onClose={handleCloseLeaderboardModal}
          leaderBoard={calculateLeaderboard(quiz)} totalPointsQuiz={calculateTotalPointsOfQuiz(quiz)}/>

      <SendEmailQuizPlayersModal 
        open={showInvitationModal} onClose={handleCloseInvitationModal}
        quizPlayers={getQuizPlayersWithEmail()}
        handleSendMail={sendInvitationMail} />

      <SnackbarNotification
        textToDisplay={snackbarMessage}
        setShouldOpenSnackbar={setShowSnackbar}
        shouldOpenSnackbar={showSnackbar}
        horizontalLocation='center'
        verticalLocation='top'
        severity='success'
      />
    </>
  );
};

export default QuizData;
