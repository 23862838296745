import RoundList from './RoundList';
import RoundPopup from './RoundPopup';
import QuestionListDesktop from '../../Question/Components/QuestionList/QuestionListDesktop';
import { RoundsDesktopScreenProps } from '../Properties/RoundsDesktopScreenProps';

const Rounds: React.FC<RoundsDesktopScreenProps> = ({ quiz, selectedRound,
    defaultNewRound, handelOnDeleteRound, handleRoundClick, onAddRoundClick,
    handleOnReorderRounds, onQuizChange, onRoundChange, saveNewRound, setShouldOpenPopup,
    shouldOpenPopup }) => {

    return (
        <>
            <div id="round-container">
                <div id="round-list-container">
                    <div className="round-column-content">
                        <RoundList
                            quiz={quiz}
                            selectedRound={selectedRound}
                            onRoundClick={handleRoundClick}
                            onAddRound={onAddRoundClick}
                            onReorderRounds={handleOnReorderRounds}
                            onDeleteRound={handelOnDeleteRound}
                            onRoundChange={onRoundChange} />
                    </div>
                </div>

                <div id="question-list-container">
                    <div className="question-column-content">
                        <QuestionListDesktop
                            selectedRound={selectedRound}
                            quiz={quiz}
                            onQuizChange={onQuizChange}
                        />
                    </div>
                </div>
            </div>

            <RoundPopup
                roundDataProp={defaultNewRound()}
                onRoundPopupDataChange={saveNewRound}
                shouldOpenPopup={shouldOpenPopup}
                setShouldOpenPopup={setShouldOpenPopup}></RoundPopup>
        </>
    );
}

export default Rounds;