import { AxiosInstance } from "axios";
import { AuthContextProps } from "react-oidc-context";
import getApiClient from "../../Shared/ApiClient/AxiosClient";
import { UpdateAnswer } from "../../../interfaces/Answers/UpdateAnswer";
import { CreateAnswer } from "../../../interfaces/Answers/CreateAnswer";

export class AnswerRepo {
    private readonly apiClient: AxiosInstance;

    constructor(auth:AuthContextProps) {
        this.apiClient = getApiClient(auth);
      }

      async DeleteAnswerAsync(id: number): Promise<void> {
        try {
          const response = await this.apiClient.delete(`/answer/${id}`)
        }
        catch (error) {
          throw error
        }
      }

      async UpdateAnswerAsync(updateAnswer: UpdateAnswer): Promise<void> {
        try {
          await this.apiClient.put("/answer/", updateAnswer);
        }
        catch (error) {
          throw error;
        }
      }

      async CreateAnswerAsync(createAnswer: CreateAnswer): Promise<number> {
        try {
          const response = await this.apiClient.post(`/answer`, createAnswer);
          return response.data.id as number;
        }
        catch (error) {
          throw error
        }
      }
}