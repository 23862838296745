import React, { useState } from 'react';
import { Dialog, DialogContent, Typography, Card, CardActionArea, CardHeader, IconButton, DialogActions, Button, CircularProgress } from '@mui/material';
import { Delete, Image, Videocam, Audiotrack } from '@mui/icons-material';
import ImagePreview from './ImagePreview';
import VideoPreview from './VideoPreview';
import AudioPreview from './AudioPreview';
import { AttachmentPreviewProps } from './Props/AttachmentPreviewProps';
import { AttachmentType } from './Enum/AttachmentType';

const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({ attachment, onDeleteAttachment }) => {
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDeleteClick = () => setConfirmDelete(true);
  const handleDeleteClose = () => setConfirmDelete(false);

  const handleOnDeleteSubmit = async () => {
    setConfirmDelete(false);
    setIsDeleting(true); 
    onDeleteAttachment(attachment.attachmentId);
  };

  const renderPreviewIcon = () => {
    if (attachment.contentType.startsWith(AttachmentType.Image)) {
      return <Image />;
    } else if (attachment.contentType.startsWith(AttachmentType.Video)) {
      return <Videocam />;
    } else if (attachment.contentType.startsWith(AttachmentType.Audio)) {
      return <Audiotrack />;
    } else {
      return <Typography>Unsupported format</Typography>;
    }
  };

  const renderPreviewContent = () => {
    if (attachment.contentType.startsWith(AttachmentType.Image)) {
      return <ImagePreview attachment={attachment} isThumbnail />;
    } else if (attachment.contentType.startsWith(AttachmentType.Video)) {
      return <VideoPreview attachment={attachment} isThumbnail />;
    } else if (attachment.contentType.startsWith(AttachmentType.Audio)) {
      return <AudioPreview attachment={attachment} isThumbnail />;
    }
  };

  return (
    <>
      <Card variant="outlined" className='AttachmentPreview'>
        <CardHeader
          className='AttachmentPreviewHeader'
          avatar={renderPreviewIcon()}
          action={
            <IconButton className='deleteIcon' onClick={handleDeleteClick}>
              <Delete />
            </IconButton>
          }
        />
        <CardActionArea className='AttachmentPreviewCardContent' onClick={handleClickOpen}>
          {isDeleting ? (
            <div className='loaderWrapper'>
              <CircularProgress 
                size={50} 
                className='loader'
              />
            </div>
          ) : (
            renderPreviewContent()
          )}
        </CardActionArea>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          {attachment.contentType.startsWith('image') && <ImagePreview attachment={attachment} />}
          {attachment.contentType.startsWith('video') && <VideoPreview attachment={attachment} />}
          {attachment.contentType.startsWith('audio') && <AudioPreview attachment={attachment} />}
        </DialogContent>
      </Dialog>

      <Dialog open={confirmDelete} onClose={handleDeleteClose}>
        <DialogContent>
          <Typography>Zeker weten dat je dit bestand wilt verwijderen?</Typography>
          <div>{renderPreviewContent()}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnDeleteSubmit} disabled={isDeleting}>Verwijderen</Button>
          <Button onClick={handleDeleteClose} disabled={isDeleting}>Annuleer</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AttachmentPreview;
