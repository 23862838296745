import React, { useEffect, useState } from 'react';
import { AlertColor } from '@mui/material';
import { QuizTeamWithQuizPlayers } from '../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers';
import { QuizPlayer } from '../../../interfaces/QuizPlayer/QuizPlayer';
import { CreateQuizTeam as createQuizTeam } from '../../../interfaces/QuizTeam/CreateQuizTeam';
import { CreateQuizPlayer } from '../../../interfaces/QuizPlayer/CreateQuizPlayer';
import { QuizPlayerKind } from '../../../interfaces/QuizPlayer/QuizPlayerKind';
import { useAuth } from 'react-oidc-context';
import { QuizTeamRepo } from '../Repo/QuizTeamRepo';
import { QuizPlayerRepo } from '../../QuizPlayer/Repo/QuizPlayerRepo';
import { QuizTeam } from '../../../interfaces/QuizTeam/QuizTeam';
import { QuizUser } from '../../../interfaces/QuizUser/QuizUser';
import SnackbarNotification from '../../Shared/Snackbar/SnackbarNotifcation';
import { SnackbarSeverityEnum } from '../../Shared/Snackbar/SnackbarSeverityEnum';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import { updateQuizRemoveQuizPlayerFromQuiz, updateQuizSetQuizPlayerToUnattached, updateQuizWithAddedPlayerToUnassignedTeam, updateQuizWithADeletedQuizTeam, updateQuizWithNewlyAddedQuizUsersToUnassignedTeam, updateQuizWithNewQuizPlayers, updateQuizWithNewQuizTeam, updateQuizWithQuizTeamAndUnassingedQuizPlayer, updateQuizWithRemovedExternalPlayerFromUnassingedTeam, updateQuizWithRemovedQuizPlayerFromUnAssingedQuizTeam } from "../Shared/QuizTeamStateHelper"
import { UNASSIGNED_TEAM_ID } from '../../Shared/Constants/UnassignedTeamId';
import '../Styles/QuizTeamManager.scss'
import QuizTeamManagerFullScreen from './QuizTeamManagerFullScreen';
import QuizTeamManagerMobile from './QuizTeamManagerMobile';
import { useMediaQuery } from 'react-responsive';
import { QuizTeamManagerProps } from '../Props/QuizTeamManagerProps';
import { playerQuizLocation } from '../Enum/playerQuizLocation';

interface QuizDataProps {
  quiz: Quiz;
  setUpdatedQuiz: (quiz: Quiz) => void;
}

const QuizTeamManager: React.FC<QuizDataProps> = ({ quiz, setUpdatedQuiz }) => {
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>(SnackbarSeverityEnum.Warning);

  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})

  const auth = useAuth();
  const quizTeamRepo = new QuizTeamRepo(auth);
  const quizPlayerRepo = new QuizPlayerRepo(auth);

  useEffect(() => {
  }, [quiz.id, auth]);


  const updateQuizTeams = (
    prevQuizTeams: QuizTeamWithQuizPlayers[],
    fromQuizTeamId: number,
    toQuizTeamId: number,
    quizPlayerId: number
  ) => 
    {
    const fromQuizTeamIndex = prevQuizTeams.findIndex((quizTeam) => quizTeam.id == fromQuizTeamId);
    const toQuizTeamIndex = prevQuizTeams.findIndex((quizTeam) => quizTeam.id == toQuizTeamId);
    const quizPlayerIndex = prevQuizTeams[fromQuizTeamIndex].quizPlayers.findIndex((quizPlayer) => quizPlayer.id == quizPlayerId);
    const movedQuizPlayer = prevQuizTeams[fromQuizTeamIndex].quizPlayers[quizPlayerIndex];

    let updatedQuiz = updateQuizWithNewQuizPlayers(quiz, prevQuizTeams, fromQuizTeamIndex, toQuizTeamIndex, quizPlayerId, movedQuizPlayer, quizPlayerIndex);
    setUpdatedQuiz(updatedQuiz);
  };

 function moveQuizPlayerToTeam(quizPlayerId: number, fromQuizTeamId: number, toQuizTeamId: number) {
    if (toQuizTeamId == UNASSIGNED_TEAM_ID) {
      quizPlayerRepo.MoveQuizPlayerToUnassigned(() => {
        updateQuizTeams(quiz.quizTeams, fromQuizTeamId, toQuizTeamId, quizPlayerId)
      }, quiz.id, quizPlayerId);
    } else {
      quizPlayerRepo.MoveQuizPlayerToQuizTeam(() => {
        updateQuizTeams(quiz.quizTeams, fromQuizTeamId, toQuizTeamId, quizPlayerId)
      }, quiz.id, quizPlayerId, toQuizTeamId);
    }
  }

  function GetQuizPlayer(playerQuizId: number, quizTeamId: number) : QuizPlayer {
    let foundQuizPlayer = quiz.quizTeams.find(x => x.id === quizTeamId)
                            .quizPlayers.find(x => x.id === playerQuizId);
                        
    return foundQuizPlayer;
  }

  const onPlayerDeleteInAssignedTeam = (quizPlayerId: number, quizTeamId: number) => {
    onDeleteQuizPlayer(quizPlayerId, quizTeamId);
  }

  const onPlayerDeleteInUnAssignedTeam = (quizPlayerId: number, quizTeamId: number) => {
    onDeleteQuizPlayer(quizPlayerId, quizTeamId);
  }

 function onDeleteQuizPlayer(quizPlayerId: number, quizTeamId: number){
    const foundQuizPlayer = GetQuizPlayer(quizPlayerId, quizTeamId);
       
    if(isQuizPlayerInternal(foundQuizPlayer)){ 
      setQuizPlayerToUnattached(quizTeamId, quizPlayerId);
    }
    else{
      removeQuizPlayerFromQuiz(quizTeamId, quizPlayerId);
    } 
  }

  function isQuizPlayerInternal(quizPlayer: QuizPlayer) {
    return quizPlayer.userId !== null;
  }

  function removeQuizPlayerFromQuiz(quizTeamId: number, quizPlayerId: number) {
    quizPlayerRepo.RemoveQuizPlayer(() => {
      let updatedQuiz = updateQuizRemoveQuizPlayerFromQuiz(quiz, quizTeamId, quizPlayerId);
      setUpdatedQuiz(updatedQuiz);
    }, quiz.id, quizPlayerId);
  }

 function setQuizPlayerToUnattached(quizTeamId: number, quizPlayerId: number){
    quizPlayerRepo.RemoveQuizPlayer(() => {
      let updatedQuiz = updateQuizSetQuizPlayerToUnattached(quiz, quizTeamId, quizPlayerId);
      setUpdatedQuiz(updatedQuiz);
    }, quiz.id, quizPlayerId);
  }

  function checkIfPlayerIsInQuiz(email: string): { found: boolean; location: playerQuizLocation; team?: QuizTeamWithQuizPlayers } {

    for (const team of quiz.quizTeams) {
      const quizPlayer = team.quizPlayers.find(
        quizPlayer =>
          (quizPlayer.invitationMail &&
            quizPlayer.invitationMail.toUpperCase() === email.toUpperCase()) ||
          (quizPlayer.userMail &&
            quizPlayer.userMail.toUpperCase() === email.toUpperCase())
      );
      if (quizPlayer) {
        return { found: true, location: playerQuizLocation.Team, team };
      }
    }
  
    const unattachedPlayer = quiz.quizUsersNotAttachedToQuiz.find(user => user.email === email);
    if (unattachedPlayer) {
      return { found: true, location: playerQuizLocation.Unattached };
    }
  
    return { found: false, location: null };
  }

  function GetNewGuestQuizTeam(quizTeamId: number, guestQuizTeamName: string) : QuizTeamWithQuizPlayers {
    const newQuizTeam: QuizTeamWithQuizPlayers = {
      id: quizTeamId,
      teamId: null,
      isGuestTeam: true,
      name: guestQuizTeamName,
      quizPlayers: [],
      focusOnQuizTeam: true
    };

    return newQuizTeam;
  }

  function handleAddGuestQuizTeamClick(guestQuizTeamName: string){
    return quizTeamRepo.CreateQuizTeam((quizTeam: QuizTeam) => {
      
      const newGuestQuizTeam = GetNewGuestQuizTeam(quizTeam.id, guestQuizTeamName);
      let updatedQuiz = updateQuizWithNewQuizTeam(quiz, newGuestQuizTeam);
      setUpdatedQuiz(updatedQuiz);
    },
      quiz.id,
      {
        QuizTeamName: guestQuizTeamName,
        Sequence: 0
      } as createQuizTeam);
  }

  function createQuizPlayer(createQuizPlayer: CreateQuizPlayer) {
    return quizPlayerRepo.CreateNewQuizPlayer((quizPlayer: QuizPlayer) => {
      const teamIndex = quiz.quizTeams.findIndex(team => team.id === UNASSIGNED_TEAM_ID);
      let updatedQuiz = updateQuizWithAddedPlayerToUnassignedTeam(quiz, teamIndex, quizPlayer);
      setUpdatedQuiz(updatedQuiz);
    },
      quiz.id,
      createQuizPlayer);
  }

async function handleAddQuizUsersClick(selectedQuizUsers: QuizUser[]): Promise<void> {
  const selectedExistingPlayerIds = selectedQuizUsers.map(quizUser => quizUser.id);
  const remainingquizUsersThatArentAttachedToQuiz = quiz.quizUsersNotAttachedToQuiz.filter(player => !selectedExistingPlayerIds.includes(player.id));

  let newQuizPlayers: QuizPlayer[] = [];

  const createQuizPlayerPromises = selectedQuizUsers.map(async (player) => {
    const createQuizPlayer = {
      userId: player.id,
      quizPlayerKind: QuizPlayerKind.EXISTING
    } as CreateQuizPlayer

    return new Promise<QuizPlayer>((resolve) => {
      quizPlayerRepo.CreateNewQuizPlayer((quizPlayer: QuizPlayer) => {
        newQuizPlayers.push(quizPlayer);
        resolve(quizPlayer);
      }, quiz.id, createQuizPlayer);
    });
  });

  await Promise.all(createQuizPlayerPromises);

  const teamIndex = quiz.quizTeams.findIndex(team => team.id === UNASSIGNED_TEAM_ID);
  let updatedQuiz = updateQuizWithNewlyAddedQuizUsersToUnassignedTeam(quiz, teamIndex, newQuizPlayers, remainingquizUsersThatArentAttachedToQuiz);
  setUpdatedQuiz(updatedQuiz);
}
  

function SetSnackbarOptions(message: string, severity: SnackbarSeverityEnum, showSnackbar: boolean){
  setSnackbarMessage(message);
  setSnackbarSeverity(severity);
  setShowSnackbar(showSnackbar);
}

const handleAddQuizPlayerWithEmail = (email: string) => {
      var quizPlayerInfo = checkIfPlayerIsInQuiz(email);

      if (quizPlayerInfo.found) 
      {
          if (quizPlayerInfo.location === playerQuizLocation.Team) 
          {
            SetSnackbarOptions("Speler is al toegevoegd aan de quiz.", SnackbarSeverityEnum.Error, true);
          }
          else if (quizPlayerInfo.location ===  playerQuizLocation.Unattached)
          {
            let quizUser = quiz.quizUsersNotAttachedToQuiz.find(x => x.email.toUpperCase() == email.toUpperCase());
            
            createQuizPlayer({userId: quizUser.id, quizPlayerKind: QuizPlayerKind.EXISTING} as CreateQuizPlayer)
            SetSnackbarOptions("Speler bestaat al en werd toegevoegd aan de niet toegewezen lijst.\n(Geen Mail verstuurd)", SnackbarSeverityEnum.Warning, true);
          }
        }
        else 
        {
          createQuizPlayer({invitationMail: email, quizPlayerKind: QuizPlayerKind.INVITATION} as CreateQuizPlayer)
          SetSnackbarOptions(`Er is een mail verstuurd naar ${email}.`, SnackbarSeverityEnum.Success, true);
        }
}

function handleAddGuestQuizPlayer(guestName: string) {
  quizPlayerRepo.CreateNewQuizPlayer((quizPlayer: QuizPlayer) => {

    const teamIndex = quiz.quizTeams.findIndex(team => team.id === UNASSIGNED_TEAM_ID);
    let updatedQuiz = updateQuizWithAddedPlayerToUnassignedTeam(quiz, teamIndex, quizPlayer);
    setUpdatedQuiz(updatedQuiz);
  },
    quiz.id,
    {
      guestName: guestName,
      quizPlayerKind: QuizPlayerKind.GUEST
    } as CreateQuizPlayer);
}

const handleQuizTeamDelete = (quizTeamToDelete: QuizTeamWithQuizPlayers) => {
  if (quizTeamToDelete.id === UNASSIGNED_TEAM_ID) {
    return;
  }

  return quizTeamRepo.RemoveQuizTeam(() => {
    let updatedQuiz = updateQuizWithADeletedQuizTeam(quiz, quizTeamToDelete);
    setUpdatedQuiz(updatedQuiz);
  }, quiz.id, quizTeamToDelete.id)
}

const sharedQuizTeamManagerProps = {
  quizUsersNotAttachedToQuiz: quiz.quizUsersNotAttachedToQuiz,
  quizTeams: quiz.quizTeams,

  onAddClickQuizPlayerGuest: handleAddGuestQuizPlayer,
  onAddClickQuizPlayerMultiSelect: handleAddQuizUsersClick,
  onAddClickQuizPlayerWithEmail: handleAddQuizPlayerWithEmail,

  onAddGuestQuizTeam: handleAddGuestQuizTeamClick,
  onDeleteQuizTeam: handleQuizTeamDelete,
  moveQuizPlayerToTeam: moveQuizPlayerToTeam,
  onQuizPlayerDeleteInAssignedTeam: onPlayerDeleteInAssignedTeam,
  onQuizPlayerDeleteInUnassignedTeam: onPlayerDeleteInUnAssignedTeam
 
} as QuizTeamManagerProps;

  return (
    <>
      {isDesktopOrLaptop && <QuizTeamManagerFullScreen {...sharedQuizTeamManagerProps} />}
      {isTabletOrMobile && <QuizTeamManagerMobile {...sharedQuizTeamManagerProps} />}
        
      <SnackbarNotification
        textToDisplay={snackbarMessage}
        setShouldOpenSnackbar={setShowSnackbar}
        shouldOpenSnackbar={showSnackbar}
        horizontalLocation='center'
        verticalLocation='top'
        severity={snackbarSeverity}
      >
      </SnackbarNotification>
    </>
  );
};

export default QuizTeamManager;