import React from 'react';
import YouTube from 'react-youtube';
import { VideoPreviewProps } from './Props/VideoPreviewProps';
import { AttachmentSource } from './Enum/AttachmentSource';

const VideoPreview: React.FC<VideoPreviewProps> = ({ attachment, isThumbnail }) => {
  if (attachment.source == AttachmentSource.YouTube) {
    return (
      <YouTube
        videoId={attachment.fileId}
        opts={{
          width: isThumbnail ? 'auto' : '100%',
          height: isThumbnail ? '150' : '390'
        }}
      />
    );
  }

  if(attachment.source == AttachmentSource.GoogleDrive){
    return (
      <>
      <iframe
        src={`https://drive.google.com/file/d/${attachment.fileId}/preview`}
        width="auto"
        height="150"
        allow="autoplay; encrypted-media"
        allowFullScreen
      >
      </iframe>
      </>
    );
  }

  return (
  <>
    <video src={attachment.url}></video>
  </>
  );
};

export default VideoPreview;
