import { useState } from "react";
import { IconButton, Paper} from "@mui/material";
import { Round } from "../../../interfaces/Quiz/Round";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { CreateOrEditRound } from "../../../interfaces/Round/CreateOrEditRound";
import EditIcon from '@mui/icons-material/Edit';
import RoundPopup from "./RoundPopup";
import DeleteModal from "../../Shared/Delete/DeleteModal";

interface SortableRoundProps {
  round: Round;
  selectedRound: Round | null;
  onRoundClick: (round: Round) => void;
  onDeleteRound: (roundId: number) => void;
  onRoundChange: (updatedRound: CreateOrEditRound) => void;
}

function SortableRound({
  round,
  selectedRound,
  onRoundClick,
  onDeleteRound,
  onRoundChange
}: SortableRoundProps) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [shouldOpenPopup, setShouldOpenPopup] = useState(false);
  
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: round.id,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    }
  })

  const createOrEditRoundObject = () => {
    return {
      roundId : round.id,
      countOfQuestions : round.questionsFromRound.length,
      maxPoints : round.maxPoints,
      roundName : round.name,
      kindOfRound: round.kindOfRound,
      quizId : round.quizId
    } as CreateOrEditRound
  }

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  }

  const handleOnDeleteButtonClick = () => {
    setShowConfirmationModal(true);
  }

  const handleOnEditRoundButtonClick = () => {
    setShouldOpenPopup(true);
  }

  const handleOnMouseEnter = () => {
    setIsHovered(true)
  }

  const handleOnMouseLeave = () => {
    setIsHovered(false)
  }

  const handleOnRoundClick = () => {
    onRoundClick(round)
  }

  const handleOnRoundChange = (updatedRound:CreateOrEditRound) => {
    onRoundChange(updatedRound)
  }

  return (
    <>
      <div
        className={`round-list-element`}
        ref={setNodeRef}
        style={styles}
        onMouseEnter={handleOnMouseEnter} 
        onMouseLeave={handleOnMouseLeave}
        onClick={handleOnRoundClick}
      >
        <Paper elevation={3} className={`round-container ${round.id == selectedRound?.id ? "selected-round" : ""}`}>
          <div className="round-container-left">
            {round.name} ({round.questionsFromRound.length})
          </div>
          <div className="round-container-right">
            <IconButton
              className={`round-container-button ${isHovered ? '' : "round-container-button-hidden"}`} 
              aria-label="Delete"
              onClick={handleOnDeleteButtonClick}
            >
              <DeleteIcon />
            </IconButton>

            <IconButton
              className={`round-container-button ${isHovered ? '' : "round-container-button-hidden"}`} 
              aria-label="Edit"
              onClick={handleOnEditRoundButtonClick}
            >
              <EditIcon/>
            </IconButton>

            <IconButton className="round-container-drag" color="primary" aria-label="Drag" {...attributes} {...listeners}>
              <DragIndicatorIcon />
            </IconButton>
          </div>
        </Paper>
      </div>

      <DeleteModal
        deleteCallback={() => onDeleteRound(round.id)}
        setOpenModal={setShowConfirmationModal}
        openModal={showConfirmationModal}
        subject='Vraag'
        subjectValue={round.name}/>

      <RoundPopup
                roundDataProp={createOrEditRoundObject()}
                onRoundPopupDataChange={handleOnRoundChange}
                shouldOpenPopup={shouldOpenPopup}
                setShouldOpenPopup={setShouldOpenPopup}></RoundPopup>
    </>
  );
}

export default SortableRound;
