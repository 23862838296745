import { AxiosInstance } from "axios";

import { getUnauthorizedApiClient } from "../../Shared/ApiClient/AxiosClient";

export class MailRepo {
    private readonly apiClient:AxiosInstance;
    
    constructor() {
      this.apiClient = getUnauthorizedApiClient();
    }
  
    async IsKeyValid(callback: any, key: string): Promise<void> {

      await this.apiClient.get(`/mail/${key}/isvalid`).then((res: any) => {
        let data = res.data as boolean
        callback(data);
      })
    }

    async InvitationToQuiz(callback: any, key: string, hasAccepted: boolean): Promise<void> {
      await this.apiClient.put(`/mail/${key}/InvitationToQuiz?hasAccepted=${hasAccepted}`).then((res: any) => {
        callback();
      })
    }

    async RegisterToSystemWithQuiz(callback: any, key: string, userId: string): Promise<void> {
      await this.apiClient.put(`/mail/${key}/RegisterToSystemWithQuiz/${userId}`).then((res: any) => {
        callback();
      })
    }
}  