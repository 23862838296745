import { useEffect, useState } from 'react';
import { Round } from '../../../interfaces/Quiz/Round';
import { CreateRoundPlacements } from '../../../interfaces/Round/CreateRoundPlacement';
import { CreateRound } from "../../../interfaces/Round/CreateRound";
import { RoundUpdate } from '../../../interfaces/Round/UpdateRound';
import { useAuth } from 'react-oidc-context';
import { RoundRepo } from '../Repo/RoundRepo';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import { CopyQuizWithCopiesOfRounds, UpdateQuizWithDeleteRound, UpdateQuizWithNewRound, UpdateQuizWithUpdatedRound } from '../Shared/RoundStateHelper';
import { CreateOrEditRound } from '../../../interfaces/Round/CreateOrEditRound';
import { useMediaQuery } from 'react-responsive'
import RoundsFullScreenComponent from './RoundsDesktop';
import { QuizDataProps } from '../Properties/QuizDataProps';
import RoundsMobileComponent from './RoundsMobile';
import { KindOfRoundChoices } from '../../../interfaces/Round/KindOfRoundChoices';


const Rounds: React.FC<QuizDataProps> = ({ quiz, setUpdatedQuiz }) => {
    const auth = useAuth();
    const roundRepo = new RoundRepo(auth);
    const [selectedRound, setSelectedRound] = useState<Round>(quiz.rounds[0]);
    const [loading, setLoading] = useState(true);
    const [shouldOpenPopup, setShouldOpenPopup] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    useEffect(() => {
        setLoading(false);
    }, [quiz, auth]);

    //TODO: This also triggers on a hoverOver, it should not happen
    const onRoundChange = (updatedRound: CreateOrEditRound) => {
        if (selectedRound) {
            const updateRound: RoundUpdate = {
                id: selectedRound.id,
                name: updatedRound.roundName,
                kindOfRound: updatedRound.kindOfRound,
                maxPoints: updatedRound.maxPoints,
                quizId: updatedRound.quizId,
            };

            roundRepo.UpdateRound((round: Round) => {
                let updatedQuizWithRound = UpdateQuizWithUpdatedRound(quiz,  updatedRound);
                onQuizChange(updatedQuizWithRound);
            }, updateRound, quiz.id);
        }
    }

    const defaultNewRound = (): CreateOrEditRound => {
        return {
            kindOfRound: "Normal",
            maxPoints: 10,
            countOfQuestions: 10,
            quizId: quiz.id,
            roundName: "",
            roundId: selectedRound.id,
            isNewRound: true
        };
    };

    function handleRoundClick(round: Round) {
        setSelectedRound(round);
    }

    function onAddRoundClick() {
        setShouldOpenPopup(true);
    }

    function saveNewRound(roundData: CreateOrEditRound) {
        const newRound: CreateRound = {
            quizId: quiz.id,
            name: roundData.roundName,
            countOfQuestions: roundData.countOfQuestions,
            maxPoints: roundData.maxPoints,
            kindOfRound: KindOfRoundChoices.NORMAL,
            placement: CreateRoundPlacements.END
        };

        roundRepo.CreateRoundAsync((createdRound: Round) => {
            const newRoundUi: Round = {
                id: createdRound.id,
                name: createdRound.name,
                totalQuestions: createdRound.totalQuestions,
                questionsFromRound: createdRound.questionsFromRound,
                maxPoints: createdRound.maxPoints,
                sequence: quiz.rounds.length + 1,
                quizId: quiz.id,
                kindOfRound: createdRound.kindOfRound
            }
            setSelectedRound(newRoundUi);
            let updatedQuiz = UpdateQuizWithNewRound(quiz, newRoundUi);       
            onQuizChange(updatedQuiz);
        }, newRound);
    }

    function handleOnReorderRounds(rounds: Round[]) {
        let updatedQuiz = CopyQuizWithCopiesOfRounds(quiz, rounds);
        setUpdatedQuiz(updatedQuiz)
    }

    function handelOnDeleteRound(roundId: number) {
        let roundIndex = quiz.rounds.findIndex(x => x.id === roundId);
        if (roundIndex === -1) return;
        let roundToDelete = quiz.rounds[roundIndex];

        if (quiz.rounds.length === 1) {
            alert("Won't delete, at least one round must exist"); // TODO: Replace alert with popup
            return;
        }

        let updatedQuiz = UpdateQuizWithDeleteRound(quiz, roundToDelete);
        roundRepo.DeleteRound(() => {
            let selectedRoundIndex = roundIndex - 1;
            if (selectedRoundIndex < 0) selectedRoundIndex = 0;
            if (selectedRoundIndex >= updatedQuiz.rounds.length) selectedRoundIndex = updatedQuiz.rounds.length - 1;

            onQuizChange(updatedQuiz);
            setSelectedRound(updatedQuiz.rounds[selectedRoundIndex]);

        }, quiz.id, roundId);
    }

    function onQuizChange(quiz: Quiz) {
        setUpdatedQuiz({ ...quiz });
        setSelectedRound({ ...quiz.rounds.find(round => round.id === selectedRound.id) });
    }

    if (loading) { //(FCO) => Make this more good looking and middle of the page (Make sure this is shared component so we can use it acrross the page)
        return <div>Loading...</div>;
    }

    return (
        <>
            {isDesktopOrLaptop && <RoundsFullScreenComponent

                quiz={quiz}
                selectedRound={selectedRound}
                shouldOpenPopup={shouldOpenPopup}
                defaultNewRound={defaultNewRound}
                handelOnDeleteRound={handelOnDeleteRound}
                handleOnReorderRounds={handleOnReorderRounds}
                handleRoundClick={handleRoundClick}
                onAddRoundClick={onAddRoundClick}
                onQuizChange={onQuizChange}
                onRoundChange={onRoundChange}
                saveNewRound={saveNewRound}
                setShouldOpenPopup={setShouldOpenPopup}
                key={null}
            />

                || isTabletOrMobile &&
                <RoundsMobileComponent
                    quiz={quiz}
                    selectedRound={selectedRound}
                    shouldOpenPopup={shouldOpenPopup}
                    defaultNewRound={defaultNewRound}
                    handelOnDeleteRound={handelOnDeleteRound}
                    handleRoundClick={handleRoundClick}
                    onQuizChange={onQuizChange}
                    onRoundChange={onRoundChange}
                    saveNewRound={saveNewRound}
                    setShouldOpenPopup={setShouldOpenPopup}
                    key={null}
                />
            }
        </>
    );
}

export default Rounds;