import { arrayMove } from "@dnd-kit/sortable";
import { UpdateQuestion } from "../../../interfaces/Questions/UpdateQuestion";
import { Question } from "../../../interfaces/Quiz/Question";
import { QuestionsFromRound } from "../../../interfaces/Quiz/QuestionsFromRound";
import { Quiz } from "../../../interfaces/Quiz/Quiz";
import { Round } from "../../../interfaces/Quiz/Round";
import { Attachment } from "../../Shared/Components/FileUpload/interface/Attachment";
import { Answer } from "../../../interfaces/Quiz/Answer";

export function UpdateQuizWithDeletedQuestion(quiz: Quiz, round: Round, questionId: number): Quiz | null {
    const roundIndex = quiz.rounds.findIndex(x => x.id === round.id);
    if (roundIndex === -1) return null;

    const updatedQuestionsFromRound = quiz.rounds[roundIndex].questionsFromRound.filter(x => x.id !== questionId);
    updatedQuestionsFromRound.forEach((question: QuestionsFromRound, index: number) => {
        question.sequence = index + 1
    })
    const updatedRound = {
        ...quiz.rounds[roundIndex],
        questionsFromRound: updatedQuestionsFromRound
    };

    const updatedRounds = quiz.rounds.map((round, index) => index === roundIndex ? updatedRound : round);

    const updatedQuiz = {
        ...quiz,
        rounds: updatedRounds
    };

    return updatedQuiz;
}

export function UpdateQuizWithUpdatedRoundAndUpdatedQuestion(quiz: Quiz, round: Round, newQuestion: Question, calculatedSequence: number, answerId: number): Quiz | null {
    let selectedRoundIndex = quiz.rounds.findIndex(x => x.id === round.id);
    if (selectedRoundIndex === -1) return null;

    const updatedQuestions = [
        ...quiz.rounds[selectedRoundIndex].questionsFromRound,
        {
            id: newQuestion.id,
            description: newQuestion.description,
            sequence: calculatedSequence,
            maxPoints: 0,
            answers: [{
              id: answerId,
              description: ""
            } as Answer],
            attachments: []
        } as QuestionsFromRound
    ];

    const updatedRound = {
        ...quiz.rounds[selectedRoundIndex],
        questionsFromRound: updatedQuestions
    } as Round;

  const updatedQuiz: Quiz = {
    ...quiz,
    rounds: [
      ...quiz.rounds.slice(0, selectedRoundIndex),
      updatedRound,
      ...quiz.rounds.slice(selectedRoundIndex + 1)
    ]
  };
  return updatedQuiz;
}

export function UpdateQuizWithDeletedAttachment(quiz:Quiz, roundId:number, questionId: number, attachmentId: number): Quiz {
  let roundIndex = quiz.rounds.findIndex(x => x.id == roundId);
  let round = quiz.rounds[roundIndex];

  let questionIndex = round?.questionsFromRound.findIndex(x => x.id == questionId);
  let question = round.questionsFromRound[questionIndex];

  let attachmentWithoutDeleted = question.attachments.filter(attachment => attachment.attachmentId != attachmentId);
  let updatedQuestion = {...question, attachments: attachmentWithoutDeleted};

  let updatedQuestions = round.questionsFromRound.map(question => {
    if(question.id == questionId) return updatedQuestion
    else return question
  })

  let updatedRounds = quiz.rounds.map(round => {
    if(round.id == roundId) return {...round, questionsFromRound: updatedQuestions}
    else return round
  })

  let updatedQuiz = {...quiz, rounds: updatedRounds}
  return updatedQuiz;
}

export function UpdateQuizWithAttachmentAdd(quiz: Quiz, roundId: number, questionId: number, attachment: Attachment): Quiz {
  let roundIndex = quiz.rounds.findIndex(x => x.id == roundId);
  let round = quiz.rounds[roundIndex];

  let questionIndex = round?.questionsFromRound.findIndex(x => x.id == questionId);
  let question = round.questionsFromRound[questionIndex];

  let updatedAttachments = [...question.attachments, attachment];
  let updatedQuestion = {...question, attachments: updatedAttachments};

  let updatedQuestions = round.questionsFromRound.map(question => {
    if(question.id == questionId) return updatedQuestion
    else return question
  })

  let updatedRounds = quiz.rounds.map(round => {
    if(round.id == roundId) return {...round, questionsFromRound: updatedQuestions}
    else return round
  })

  let updatedQuiz = {...quiz, rounds: updatedRounds}
  return updatedQuiz;
}

export function UpdateQuizWithUpdatedRoundAndQuestionChange(quiz: Quiz, selectedRound: Round, question: UpdateQuestion): Quiz | null {
    let round = quiz.rounds.find(x => x.id == selectedRound.id);
    let questionIndex = round?.questionsFromRound.findIndex(x => x.id == question.id);
    let roundIndex = quiz.rounds.findIndex(x => x.id == selectedRound.id);

    if (questionIndex === -1 || roundIndex === -1) return null;

    let hardCopyOfQuestionWithChangedQuestions = [...round.questionsFromRound];

    hardCopyOfQuestionWithChangedQuestions[questionIndex].description = question.description;
    hardCopyOfQuestionWithChangedQuestions[questionIndex].sequence = question.sequence;

    const updatedRound = {
        ...round,
        questionsFromRound: hardCopyOfQuestionWithChangedQuestions
    };

    const updatedQuiz: Quiz = {
        ...quiz,
        rounds: quiz.rounds.map((r, index) => index === roundIndex ? updatedRound : r)
    };

    return updatedQuiz;
}


export function GenerateUpdatedQuizObjectWithNewSequence(foundRound: Round, activeIndex: number, overIndex: number, quiz: Quiz, roundIndex: number) {
    const newArray = arrayMove(foundRound.questionsFromRound, activeIndex, overIndex);
  
    for (let i = 0; i < newArray.length; i++) {
      newArray[i].sequence = i + 1;
    }
  
    const updatedRound = {
      ...foundRound,
      questionsFromRound: newArray
    };
  
    const updatedQuiz = {
      ...quiz,
      rounds: quiz.rounds.map((round, index) => index === roundIndex ? updatedRound : round
      )
    };
    return { updatedQuiz, newArray };
  }
  
