import { IConfirmationMessage } from "../intrefaces/IConfirmationMessage";

const ConfirmationMessage = ({ title, message, buttonText, onButtonClick }: IConfirmationMessage) => {
    return (
        <div className="confirmation-message">
            <h2>{title}</h2>
            <p>{message}</p>
            {buttonText && (
                <button onClick={onButtonClick} className="confirmation-button">
                    {buttonText}
                </button>
            )}
        </div>
    );
};

export default ConfirmationMessage;
