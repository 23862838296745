import { FC } from 'react';
import "../../Shared/Misc/GeneralStyling.scss";
import "../../Shared/Header/Header.scss";
import "../../Shared/Form/Formlayout.scss";
import Header from '../../Shared/Header/Header';
import AddQuiz from '../Components/AddQuiz';
import "../Style/QuizData.scss";

const AddQuizView: FC = () => {
  return (
    <>
      <Header></Header>
      <main className='quizContainer'>
          <AddQuiz/>
      </main>
    </>
  );
};

export default AddQuizView;
