import { ChangedDataFromOriginalListProps } from "../Props/ChangedDataFromOriginalListProps";

const ChangedDataFromOriginalList: React.FC<ChangedDataFromOriginalListProps> = ({ changedDataItems }) => {
    return (
        <table className="changedDataListTable">
            <thead>
                <tr>
                    <th>Veld</th>
                    <th>Oude waarde</th>
                    <th>Nieuw waarde</th>
                </tr>
            </thead>
            <tbody>
                {changedDataItems.map((item, index) => (
                    <tr key={index} className="changedDataItemRow">
                        <td><strong>{item.propertyName}</strong></td>
                        <td>{item.originalValue}</td>
                        <td>{item.newValue}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ChangedDataFromOriginalList;
