import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ListIcon from '@mui/icons-material/List';
import GroupIcon from '@mui/icons-material/Group';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { QuizHeaderProps } from "../Interfaces/QuizHeaderProps";

const QuizHeader: React.FC<QuizHeaderProps> = ({ onQuizLeaderBoardsClick, onQuizMetaDataClick, onQuizPlayersClick, onQuizRoundsClick,
    showQuizScoresTab, showQuizMetaDataTab, showQuizTeamMangerTab, showQuizRoundsTab}) => {
    const [selectedTabIndex, setselectedTabIndexIndex] = useState<number>(0);

    const auth = useAuth();

    useEffect(() => {
    }, [auth]);


    const handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
        setselectedTabIndexIndex(selectedTab);
        switch (selectedTab) {
            case 0:
                onQuizMetaDataClick();
                break;
            case 1:
                onQuizRoundsClick();
                break;
            case 2:
                onQuizPlayersClick();
                break;
            case 3:
                onQuizLeaderBoardsClick();
                break;
            default:
                break;
        }
    };

    return (
        <>
            <div className="quiz-tabs">
                <Tabs value={selectedTabIndex} onChange={handleTabChange}>
                    {showQuizMetaDataTab && <Tab icon={<QuestionMarkIcon />} label="Quiz" />}
                    {showQuizRoundsTab && <Tab icon={<ListIcon />} label="Rondes" />}
                    {showQuizTeamMangerTab && <Tab icon={<GroupIcon />} label="Teams" />}
                    {showQuizScoresTab && <Tab icon={<LeaderboardIcon />} label="Scores" />}
                </Tabs>
            </div>
        </>
    );
};

export default QuizHeader;