import { Round } from "../../../../interfaces/Quiz/Round";
import { TeamRoundPoint } from "../../../../interfaces/Quiz/TeamRoundPoint";

export function GetPoint(teamRoundPoints: TeamRoundPoint[], quizTeamId: number, roundId: number): string {
    const result = teamRoundPoints.filter((trp) => trp.quizTeamId === quizTeamId && trp.roundId === roundId);
    return result.length > 0 ? result[0].points : "0";
};

export function GetTotalPointsOfQuizTeam  (teamRoundPoints: TeamRoundPoint[], quizTeamId: number) : number  {
    return teamRoundPoints
      .filter((trp) => trp.quizTeamId === quizTeamId)
      .reduce((total, trp) => total + getSanitizedPoint(trp), 0);
  };

function getSanitizedPoint(teamRoundPoint: TeamRoundPoint) : number {
    return isNaN(parseFloat(teamRoundPoint.points.toString().replace(",", "."))) ? 0 : parseFloat(teamRoundPoint.points.toString().replace(",", "."));
}


export function GetMaxRoundPoint(rounds: Round[], roundId: number): number | null {
    const selectedRound = rounds.find((round) => round.id === roundId);
    return selectedRound?.maxPoints ?? null; 
};