import React, { useState } from 'react';
import { YouTubeInputProps } from './Props/YouTubeInputProps';
import { Button, TextField} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { IsValidYouTubeUrl } from '../../Misc/YoutubeHelper';

const YouTubeInput: React.FC<YouTubeInputProps> = ({ onAddUrl }) => {
  const [url, setUrl] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
    setError(null);
  };

  const handleSubmit = () => {
    if (IsValidYouTubeUrl(url)) {
      onAddUrl(url);
      setUrl('');
    } else {
      setError('Ongelidge URL');
    }
  };

  return (
    <div className="youtube-input-container">
      <TextField
        label="Geef een YouTube URL"
        variant="outlined"
        value={url}
        onChange={handleInputChange}
        fullWidth
        error={!!error}
        helperText={error}
      />
      <Button
        variant="contained"
        className="youtube-add-button"
        color="primary"
        onClick={handleSubmit}
        style={{ alignSelf: 'flex-start' }}
      >
        <AddIcon />
      </Button>
    </div>
  );
};

export default YouTubeInput;
