import { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from 'react-oidc-context';
import { QuestionListDesktopProps } from '../../Properties/QuestionListDesktopProps';
import { GenerateUpdatedQuizObjectWithNewSequence, UpdateQuizWithAttachmentAdd, UpdateQuizWithDeletedAttachment, UpdateQuizWithDeletedQuestion, UpdateQuizWithUpdatedRoundAndQuestionChange, UpdateQuizWithUpdatedRoundAndUpdatedQuestion } from '../../Shared/QuestionStateHelper';
import { UpdateQuestion } from '../../../../interfaces/Questions/UpdateQuestion';
import { Question } from '../../../../interfaces/Quiz/Question';
import { CreateQuestion } from '../../../../interfaces/Questions/CreateQuestion';
import { QuizRepo } from '../../../Quiz/Repo/QuizRepo';
import SortableQuestionDesktop from '../SortableQuestion/SortableQuestionDesktop';
import { closestCenter, DndContext, DragEndEvent, MeasuringStrategy, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { QuestionSequence } from '../../../../interfaces/Questions/QuestionSequence';
import { QuestionRepo } from '../../Repo/QuestionRepo';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { QuestionsFromRound } from '../../../../interfaces/Quiz/QuestionsFromRound';
import { AddAttachment } from '../../../Shared/Misc/AddAttachment';
import { AttachmentSource } from '../../../Shared/Components/FileUpload/Enum/AttachmentSource';
import { FileUploadStatus } from '../../../Shared/Components/FileUpload/Enum/FileUploadStatus';
import { AnswerRepo } from '../../../Answer/Repo/AnswerRepo';
import { UpdateQuizWithCreateAnswer, UpdateQuizWithDeleteAnswer, UpdateQuizWithUpdatedAnswer } from '../../../Answer/AnswerStateHelper';
import { UpdateAnswer } from '../../../../interfaces/Answers/UpdateAnswer';
import { CreateAnswer } from '../../../../interfaces/Answers/CreateAnswer';

function QuestionListDesktop({ selectedRound, quiz, onQuizChange }: QuestionListDesktopProps) {
    const auth = useAuth();
    const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

    const descriptionRef = useRef<HTMLInputElement | null>(null);
    const quizRepo = new QuizRepo(auth)
    const questionRepo = new QuestionRepo(auth);
    const answerRepo = new AnswerRepo(auth);

    useEffect(() => {
    }, [selectedRound, auth]);

    async function onQuestionChange(updatedQuestion: UpdateQuestion) {
        await quizRepo.UpdateQuestion(async () => {
            let updatedQuiz = UpdateQuizWithUpdatedRoundAndQuestionChange(quiz, selectedRound, updatedQuestion)
            onQuizChange(updatedQuiz);
        }, updatedQuestion);
    }

    async function onQuestionAdd() {
        let sequenceForNewQuestion = Math.max(...selectedRound.questionsFromRound.map(x => x.sequence)) + 1;

        let createQuestion: CreateQuestion = {
            name: '',
            sequence: sequenceForNewQuestion,
            roundId: selectedRound.id,
        };

        await quizRepo.CreateQuestion((questionId: number, answerId: number) => {
            let questionForUi: Question = {
                description: createQuestion.name,
                roundId: createQuestion.roundId,
                sequence: createQuestion.sequence,
                id: questionId
            }

            let updatedQuiz = UpdateQuizWithUpdatedRoundAndUpdatedQuestion(quiz, selectedRound, questionForUi, sequenceForNewQuestion, answerId);
            onQuizChange(updatedQuiz);
        }, createQuestion);
    }

    async function handleOnDeleteQuestion(questionId: number) {
        await quizRepo.DeleteQuestion(async () => {
            let updatedQuiz = UpdateQuizWithDeletedQuestion(quiz, selectedRound, questionId)
            onQuizChange(updatedQuiz);
        }, questionId);
    }

    async function handleAddQuestionClick() {
        onQuestionAdd()
    }

    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;
        if (!over) return;

        const roundIndex = quiz.rounds.findIndex((round) => round.id === selectedRound.id);
        if (roundIndex === -1) return;

        const foundRound = quiz.rounds[roundIndex];

        const activeQuestion = foundRound.questionsFromRound.find((q) => q.id == active.id);
        const overQuestion = foundRound.questionsFromRound.find((q) => q.id == over.id);

        if (!activeQuestion || !overQuestion) return;

        const activeIndex = foundRound.questionsFromRound.findIndex((q) => q.id === activeQuestion.id);
        const overIndex = foundRound.questionsFromRound.findIndex((q) => q.id === overQuestion.id);

        if (activeIndex !== overIndex) {
            const { updatedQuiz, newArray } = GenerateUpdatedQuizObjectWithNewSequence(foundRound, activeIndex, overIndex, quiz, roundIndex);
            onQuizChange(updatedQuiz);

            const questionSequence: QuestionSequence = {
                sequencedQuestionsIds: newArray.map((question) => question.id)
            };

            await questionRepo.CalculateQuestionSequencesAsync(questionSequence);
        }
    }

    const measuringConfig = {
        droppable: {
            strategy: MeasuringStrategy.Always
        }
    };

    const handleAddAnswer = async (questionId: number) => {
        let answerId = await answerRepo.CreateAnswerAsync({questionId: questionId, description: ""} as CreateAnswer);
        let updateQuiz = UpdateQuizWithCreateAnswer(quiz, selectedRound.id, questionId, answerId);
        onQuizChange(updateQuiz);
    }

    const handleUpdateAnswer = async (questionId: number, description: string, answerId: number) => {
        await answerRepo.UpdateAnswerAsync({ id: answerId, questionId: questionId, description: description } as UpdateAnswer);
        let updateQuiz = UpdateQuizWithUpdatedAnswer(quiz, selectedRound.id, questionId, answerId, description);
        onQuizChange(updateQuiz);
    }

    const handleDeleteAnswer = async (answerId: number, questionId: number) => {
        await answerRepo.DeleteAnswerAsync(answerId);
        let updateQuiz = UpdateQuizWithDeleteAnswer(quiz, selectedRound.id, questionId, answerId);
        onQuizChange(updateQuiz);
    }

    const handleAddUrlAttachment = async (url: string, source: AttachmentSource, questionId: number) =>
    {
        const result = await questionRepo.AddAttachmentAsync(questionId,{
        Source: source,
        Url: url
        } as AddAttachment );

        if(result instanceof Error)
        {
        }
        else
        {
            let updatedQuiz = UpdateQuizWithAttachmentAdd(quiz, selectedRound.id, questionId, result)
            onQuizChange(updatedQuiz);
        }
    }

    const handleDeleteAttachment = async (attachmentId: number, questionId: number) => {
        await questionRepo.DeleteAttachmentAsync(questionId, attachmentId);
        let updatedQuiz = UpdateQuizWithDeletedAttachment(quiz, selectedRound.id, questionId, attachmentId);
        onQuizChange(updatedQuiz);
    }

    const handleFileUploadAttachment = async (file: File, questionId: number): Promise<FileUploadStatus> => {
        const result = await questionRepo.UploadFileAsync(questionId, file);
        if (result instanceof Error) 
        {
            return FileUploadStatus.Fail;
        } 
        else 
        {
            let updatedQuiz = UpdateQuizWithAttachmentAdd(quiz, selectedRound.id, questionId, result)
            onQuizChange(updatedQuiz);
          return FileUploadStatus.Succeeded;
        }
    };

    return (
        <>
            <div id="question-list">
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    measuring={measuringConfig}
                    modifiers={[restrictToVerticalAxis]}
                >
                    <SortableContext
                        items={quiz.rounds.find(x => x.id == selectedRound.id).questionsFromRound}
                        strategy={verticalListSortingStrategy}
                    >
                        {quiz.rounds.find(x => x.id == selectedRound.id)
                        .questionsFromRound
                        .sort((a: QuestionsFromRound, b: QuestionsFromRound) => a.sequence - b.sequence)
                        .map((q) => (
                            <SortableQuestionDesktop
                                key={`${q.id}`} 
                                initialQuestion={q}
                                onDeleteQuestion={handleOnDeleteQuestion}
                                quiz={quiz}
                                selectedRound={selectedRound}
                                onQuestionChange={onQuestionChange}
                                descriptionRef={descriptionRef}
                                onAddAnswer={handleAddAnswer}
                                onDeleteAnswer={handleDeleteAnswer}
                                onUpdateAnswer={handleUpdateAnswer}
                                onAddUrlAttachment={handleAddUrlAttachment}
                                onDeleteAttachment={handleDeleteAttachment}
                                onFileAttachmentUpload={handleFileUploadAttachment}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
            </div>

            <div>
                <Button
                    variant="outlined"
                    startIcon={<AddIcon className='AddQuestionIconButton' />}
                    fullWidth
                    className="question-list-element"
                    onClick={handleAddQuestionClick}
                >
                </Button>
            </div>
        </>
    );
}

export default QuestionListDesktop;
