import { IconButton, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, InputAdornment, TextField } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { ScoresMobileProps } from "../../Props/Scores/ScoresMobileProps";
import { useState, useEffect } from "react";
import { GetMaxRoundPoint, GetPoint } from "../../Shared/Scores/QuizTeamRoundHelper";
import { TeamRoundPoint } from "../../../../interfaces/Quiz/TeamRoundPoint";
import "../../Style/Scores/scoresMobileContainer.scss";
import { ScoreInputField } from "./ScoreInputField";

const ScoresMobileComponent: React.FC<ScoresMobileProps> = ({
    rounds,
    quizTeams,
    teamRoundPoints,
    changeTeamRoundPointState,
    saveTeamRoundPoint,
    totalQuizPoints
}) => {
    const [selectedRoundId, setSelectedRoundId] = useState<number | null>(null);

    useEffect(() => {
        setSelectedRoundId(rounds[0].id);
    }, [rounds]);

    function handleOnRoundChange(event: SelectChangeEvent<number>) {
        const roundId = Number(event.target.value);
        setSelectedRoundId(roundId);
    }

    const incrementPoints = (quizTeamId: number) => {
        const points = (Number(GetPoint(teamRoundPoints, quizTeamId, selectedRoundId)) + 1).toString();
        const teamRoundPoint = { quizTeamId, roundId: selectedRoundId, points } as TeamRoundPoint;
        changeTeamRoundPointState(teamRoundPoint);
        saveTeamRoundPoint(teamRoundPoint);
    };

    const decrementPoints = (quizTeamId: number) => {
        const points = (Number(GetPoint(teamRoundPoints, quizTeamId, selectedRoundId)) - 1).toString();
        const teamRoundPoint = { quizTeamId, roundId: selectedRoundId, points } as TeamRoundPoint;
        changeTeamRoundPointState(teamRoundPoint);
        saveTeamRoundPoint(teamRoundPoint);
    };

    return (
        <div id="scoresMobileContainer">
            <div className="roundsSelector">
            <FormControl fullWidth>
                <Select
                    value={selectedRoundId !== null ? selectedRoundId : ""}
                    onChange={handleOnRoundChange}
                >
                    {rounds.map((round) => (
                        <MenuItem key={round.id} value={round.id}>
                            {round.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            </div>

            <div id="quizTeams">
                {quizTeams.map((quizTeam) => (
                    <div key={quizTeam.id} className="quizTeamRow">
                        <div className="quizTeamName">{quizTeam.name}</div>
                        <div className="pointControls">
                            <IconButton
                                className="controlButton decrement"
                                onClick={() => decrementPoints(quizTeam.id)}
                            >
                                <RemoveIcon />
                            </IconButton>

                            <ScoreInputField
                            maxRoundPoint={GetMaxRoundPoint(rounds, selectedRoundId)}
                            teamRoundPoint={{quizTeamId: quizTeam.id, roundId: selectedRoundId, points: GetPoint(teamRoundPoints, quizTeam.id, selectedRoundId)} as TeamRoundPoint}
                            changeTeamRoundPointState={changeTeamRoundPointState}
                            saveTeamRoundPoint={saveTeamRoundPoint}
                        />
                            <IconButton
                                className="controlButton increment"
                                onClick={() => incrementPoints(quizTeam.id)}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ScoresMobileComponent;
