import React from "react";
import { UploadProgressProps } from "./Props/UploadProgressProps";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { FileUploadStatus } from "./Enum/FileUploadStatus";

const UploadProgress: React.FC<UploadProgressProps> = ({ uploadProgressFiles }) => {
    return (
        <div className="UploadProgressContainer">
            {uploadProgressFiles.map((fileStatus, index) => (
                <div
                    key={index}
                    className={`UploadProgressItem 
                        ${fileStatus.FileUploadStatus === FileUploadStatus.InProgress ? 'inProgress' : ''} 
                        ${fileStatus.FileUploadStatus === FileUploadStatus.Succeeded ? 'succeeded' : ''} 
                        ${fileStatus.FileUploadStatus === FileUploadStatus.Fail ? 'failed' : ''}`}   
                >
                    <span className='filename'>{fileStatus.File.name}</span>

                    <span className='error'>
                        {fileStatus.Error && fileStatus.Error}
                    </span>

                    <span className='status'>
                        {fileStatus.FileUploadStatus === FileUploadStatus.InProgress ? (
                            <CircularProgress size={20} />
                        ) : fileStatus.FileUploadStatus === FileUploadStatus.Succeeded ? (
                            <CheckCircleIcon />
                        ) : (
                            <ErrorIcon />
                        )}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default UploadProgress;
