import React, { useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { AddressAutocompleteProps } from '../Props/AddressAutocompleteProps';
import { Alert, CircularProgress, TextField } from '@mui/material';
import { AddressRepo } from '../../../Repo/AddressRepo';
import { Suggestion } from '../Interface/Suggestion';
import { GetAddressFromSuggestion } from '../AddressHelper';

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ onAddressSuggestionClicked }) => {
    const [query, setQuery] = useState<string>('');
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);
    const [suggestionsFound, setSuggestionsFound] = useState<boolean>(true);

    const addressRepo = new AddressRepo();

    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const userInput = e.target.value;
        setQuery(userInput);
    
        if (userInput.length < 3) {
            setSuggestions([]);
            return;
        }
    
        setLoading(true);
    
        if (cancelTokenSource) {
            cancelTokenSource.cancel("Operation canceled due to new request.");
        }

        const newCancelTokenSource = axios.CancelToken.source();
        setCancelTokenSource(newCancelTokenSource);

        const suggestions = await addressRepo.fetchSuggestions(userInput, newCancelTokenSource);
    
        setLoading(false);
        setSuggestions(suggestions);
        setSuggestionsFound(suggestions.length > 0);
    };

    const handleSuggestionClick = (suggestion: Suggestion) => {
        setQuery(suggestion.display_name);
        setSuggestions([]);
        setQuery("");

        const addressSuggestion = GetAddressFromSuggestion(suggestion);

        onAddressSuggestionClicked(addressSuggestion);
    };

    const SuggestionsList = () => (
        <ul className="suggestions-list">
            {suggestions.map((suggestion) => (
                <li
                    key={suggestion.place_id}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="suggestion-item"
                >
                    {suggestion.display_name}
                </li>
            ))}
        </ul>
    );

    const NoSuggestions = () => (
        <Alert severity="info">
            Geen suggesties gevonden
        </Alert>
    );

    return (
        <div className="autocomplete-container">
            <TextField 
                value={query} 
                onChange={handleInputChange} 
                placeholder="zoek adres ..." 
                className="autocomplete-input" 
            />
            {loading ? (
                <CircularProgress />
            ) : (
                suggestionsFound ? <SuggestionsList /> : <NoSuggestions />
            )}
        </div>
    );
};

export default AddressAutocomplete;
