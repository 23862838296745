import { useAuth } from 'react-oidc-context';
import { SaveQuizData } from '../../../interfaces/Quiz/SaveQuizData';
import { QuizRepo } from '../Repo/QuizRepo';
import { useEffect, useState } from 'react';
import SnackbarNotification from '../../Shared/Snackbar/SnackbarNotifcation';
import { CircularProgress } from '@mui/material';
import QuizHeader from './QuizHeader';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import { ClickAbleTabsOnQuizHeader } from '../Shared/ClickableTabsOnQuizHeader';
import QuizData from './QuizData';
import Rounds from '../../Round/Components/Rounds';
import Scores from '../../Scores/Components/Scores/Scores';
import QuizTeamManager from '../../QuizTeam/Components/QuizTeamManager';

interface EditQuizProps {
    quizId: number
}  

const EditQuiz: React.FC<EditQuizProps> = ({ quizId }) => {
    const auth = useAuth();
    const quizRepo = new QuizRepo(auth);
    const [activeTab, setActiveTab] = useState<string>(ClickAbleTabsOnQuizHeader.QUIZMETADATA);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [isQuizLoaded, setIsQuizLoaded] = useState<boolean>(false);
    const [quiz, setQuiz] = useState<Quiz>({} as Quiz);

    useEffect(() => {
        const fetchData = async () => {
            await quizRepo.GetQuizAsync((quiz: Quiz) => {
                setIsQuizLoaded(true);
                setQuiz(quiz);
            }, quizId);
        }
        fetchData()
            .catch(console.error);
    }, [auth]);

    const saveQuizData = async (data: SaveQuizData) => {
        await quizRepo.UpdateQuiz((updatedQuiz: Quiz) => {
            setShowSnackbar(true);
            setQuiz(updatedQuiz);
        },
            (errorData: Map<string, string[]>) => { console.log("ERROR CONVERER COMES HERE") },
            data.quizId,
            data)
    }

    const setUpdatedQuiz = async (quiz: Quiz) => {
        setQuiz(quiz);
    }

    const onQuizMetaDataClick = () => {
        setActiveTab(ClickAbleTabsOnQuizHeader.QUIZMETADATA)
    }

    const onQuizRoundsClick = () => {
        setActiveTab(ClickAbleTabsOnQuizHeader.QUIZROUNDS)
    }

    const onQuizPlayersClick = () => {
        setActiveTab(ClickAbleTabsOnQuizHeader.QUIZTEAMS)
    }

    const onQuizLeaderBoardsClick = () => {
        setActiveTab(ClickAbleTabsOnQuizHeader.QUIZSCORES)
    }

    return (
        <>
            {
                !isQuizLoaded ? (<CircularProgress size={24} />) :
                (
                    <QuizHeader
                            onQuizMetaDataClick={onQuizMetaDataClick}
                            onQuizRoundsClick={onQuizRoundsClick}
                            onQuizPlayersClick={onQuizPlayersClick}
                            onQuizLeaderBoardsClick={onQuizLeaderBoardsClick}
                            showQuizMetaDataTab={true}
                            showQuizRoundsTab={true}
                            showQuizTeamMangerTab={true}
                            showQuizScoresTab={true}/>
                    
                )
            }

            {
                !isQuizLoaded ? (<CircularProgress size={24} />)  : (
                <div id="quiz-tab-content">
                    {activeTab === ClickAbleTabsOnQuizHeader.QUIZMETADATA && <QuizData quiz={quiz} setUpdatedQuiz={setUpdatedQuiz} saveQuizData={saveQuizData} checkChangedQuizData={true}/>}
                    {activeTab === ClickAbleTabsOnQuizHeader.QUIZROUNDS && <Rounds quiz={quiz} setUpdatedQuiz={setUpdatedQuiz} />}
                    {activeTab === ClickAbleTabsOnQuizHeader.QUIZTEAMS && <QuizTeamManager quiz={quiz} setUpdatedQuiz={setUpdatedQuiz}/>}
                    {activeTab === ClickAbleTabsOnQuizHeader.QUIZSCORES && <Scores quiz={quiz} setUpdatedQuiz={setUpdatedQuiz} />}
                </div>
                )
            }

            <SnackbarNotification
                textToDisplay={"Het opslaan van de quiz is gelukt"}
                setShouldOpenSnackbar={setShowSnackbar}
                shouldOpenSnackbar={showSnackbar}
                horizontalLocation='center'
                verticalLocation='top'
                severity='success'>
            </SnackbarNotification>
        </>
    );
};

export default EditQuiz;