import { AxiosInstance } from "axios";
import { AuthContextProps } from "react-oidc-context";
import getApiClient from "../../Shared/ApiClient/AxiosClient";
import { LeaderboardWithTotalPointsOfQuiz } from "../../../interfaces/Quiz/LeaderboardWithTotalPointsOfQuiz";

export class LeaderBoardRepo {
    private readonly apiClient:AxiosInstance;
  
    constructor(auth:AuthContextProps) {
      this.apiClient = getApiClient(auth);
    }

    async GetLeaderboardByQuizId(callback: any, quizId: number): Promise<void>{
        await this.apiClient
          .get(`/leaderboard/${quizId}`)
          .then((res: any) => {
            const data = res.data as LeaderboardWithTotalPointsOfQuiz;
            callback(data);
          })
      }
}

