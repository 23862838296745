import React from 'react';
import { CardMedia } from '@mui/material';
import { ImagePreviewProps } from './Props/ImagePreviewProps';

const ImagePreview: React.FC<ImagePreviewProps> = ({ attachment, isThumbnail }) => (
  <CardMedia
    component="img"
    image={attachment.url}
    alt={attachment.fileName}
    className={isThumbnail ? 'image-thumbnail' : 'image-full'}
  />
);

export default ImagePreview;
