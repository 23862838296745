import axios, { CancelTokenSource } from 'axios';
import { Suggestion } from '../Components/Address/Interface/Suggestion';

export class AddressRepo {
    public async fetchSuggestions(query: string, cancelToken: CancelTokenSource) {
        try {
            const response = await axios.get<Suggestion[]>('https://nominatim.openstreetmap.org/search', {
                params: {
                    q: query,
                    format: 'json',
                    addressdetails: 1,
                    "accept-language": 'nl',
                    limit: 5,
                },
                cancelToken: cancelToken.token,
            });
            return response.data;
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error("Error fetching suggestions:", error);
            }
            return [];
        }
    }

    public async fetchReverseGeocode(lat: number, lng: number) {
        try {
            const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {
                params: {
                    lat: lat,
                    lon: lng,
                    format: 'json',
                },
            });
            return response.data as Suggestion;
        } catch (error) {
            console.error("Error fetching reverse geocode:", error);
            return null;
        }
    }
}
