import React, { useState } from 'react';
import { AnswerComponentProps } from '../Props/AnswerComponentProps';
import { Button, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const AnswerComponent: React.FC<AnswerComponentProps>  = ({answer, showDeleteOption, onDeleteAnswer, onUpdateAnswer}) => {
  
    const [description, setDescription] = useState<string>(answer.description);

    function handleOnAnswerChange(event: React.ChangeEvent<HTMLInputElement>){
        setDescription(event.target.value);
    }

    function handleOnDeleteButtonClick(){
        onDeleteAnswer(answer.id);
    }

    function handleOnBlurAnswer(){
        onUpdateAnswer(answer.id, description);
    }

  return (
        <>
            <div className="answer-item">
                    <TextField
                            margin="dense"
                            name="answer"
                            label="Antwoord"
                            type="text"
                            fullWidth
                            value={description}
                            onChange={handleOnAnswerChange}
                            onBlur={handleOnBlurAnswer}
                            InputLabelProps={{ shrink: true, }} />
                {
                    showDeleteOption && <>
                        <Button className="delete-answer-button" onClick={handleOnDeleteButtonClick} variant='outlined' color='error'>
                            <DeleteIcon/>
                        </Button>
                    </>
                }
            </div>
        </>
  );
};

export default AnswerComponent;
