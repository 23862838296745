import { Quiz } from "../../../interfaces/Quiz/Quiz";
import { QuizTableRow } from "../../../interfaces/Quiz/QuizTableRow";
import { QuizPlayerStatusses } from "../../../interfaces/QuizPlayer/QuizPlayerStatus";

export function UpdateQuizWithSentQuizPlayers(quiz: Quiz, quizPlayerIds: number[]): Quiz {
    const updatedQuiz = {
        ...quiz,
        quizTeams: quiz.quizTeams.map(team => ({
            ...team,
            quizPlayers: team.quizPlayers.map(quizPlayer =>
                quizPlayerIds.includes(quizPlayer.id)
                    ? { 
                        ...quizPlayer, 
                        isInvited: true, 
                        quizPlayerStatus: QuizPlayerStatusses.PENDING 
                      }
                    : quizPlayer
            )
        }))
    } as Quiz;

    return updatedQuiz;
  }

  export function UpdateStateWithOnDeletedQuiz(quizTableRows: QuizTableRow[], quizIdToBeDeleted: number): QuizTableRow[]
  {
        var updateQuizRows = [...quizTableRows.filter(quiz => quiz.id != quizIdToBeDeleted)]
        return updateQuizRows;
  }