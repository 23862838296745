export function getDateTimeString(date: Date, format: string = "YYYYMMDDHHmmss"): string {
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const dayOfWeek = date.toLocaleDateString('nl-NL', { weekday: 'long' });
    const month = date.toLocaleDateString('nl-NL', { month: 'long' });

    return format
        .replace("YYYY", year.toString())
        .replace("MMMM", month)
        .replace("MM", String(date.getMonth() + 1).padStart(2, '0'))
        .replace("DDDD", dayOfWeek)
        .replace("DD", day)
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds);
}

export function areDatesEqual(date1: Date | null, date2: Date | null): boolean {
    if (date1 === null || date2 === null) return false;

    const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), date1.getHours(), date1.getMinutes());
    const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), date2.getHours(), date2.getMinutes());

    return d1.getTime() === d2.getTime();
}