import React from 'react';
import { AnswersProps } from '../Props/AnswersProps';
import AnswerComponent from './AnswerComponent';
import '../Styles/Answers.scss'
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

const Answers: React.FC<AnswersProps>  = ({answers, onAddNewAnswer, onDeleteAnswer, onUpdateAnswer}) => {
  
  const firstAnswer = answers[0];

  return (
        <>
          <div className='answers-container'>
            <AnswerComponent answer={firstAnswer} onDeleteAnswer={onDeleteAnswer} onUpdateAnswer={onUpdateAnswer} showDeleteOption={false}/>    
            {
              answers.slice(1).map(answer => (
                  <AnswerComponent answer={answer} onDeleteAnswer={onDeleteAnswer} onUpdateAnswer={onUpdateAnswer} showDeleteOption={true}/>
              ))
            }
            <div>
              <Button className='answer-add-button' variant="outlined" onClick={onAddNewAnswer}>
                  <AddIcon />
                </Button>
            </div>
          </div>
        </>
  );
};

export default Answers;
