import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';
import styles from './DragAndDropUploader.module.scss';

interface DragAndDropUploaderProps {
  onDrop: (files: File[]) => void;
  acceptedTypes:  string[];
}

const DragAndDropUploader: React.FC<DragAndDropUploaderProps> = ({ onDrop, acceptedTypes }) => {

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    onDrop(acceptedFiles);
  }, [onDrop]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropAccepted,
    multiple: true
  });

  return (
    <Box
      {...getRootProps()}
      className={`${styles.dropzone} ${isDragActive ? styles.active : ''}`}
    >
      <input {...getInputProps()} />
      <Typography>
        {isDragActive ? 'Sleep bestanden hier...' : 'Sleep bestanden hierheen of klik om bestanden te selecteren'}
      </Typography>
    </Box>
  );
};

export default DragAndDropUploader;
