import axios from "axios";
import { AuthContextProps } from "react-oidc-context";

let baseURI: string | undefined = process.env.REACT_APP_APIURI;

export function getUnauthorizedApiClient() {
  return axios.create({
  baseURL: baseURI,
  });
}

export function getApiClient(auth:AuthContextProps) {

  if(auth.isLoading){
    const apiClient = axios.create();
    return apiClient;
  }
  else{
    if (!auth || !auth.user?.access_token) {
      throw new Error('Authorization is required');
    }

    const apiConfig = {
      baseURL: baseURI,
      headers: {}
    }
  
    if (auth != null && auth.user?.access_token) 
    {
      apiConfig.headers = {
        'Authorization': `Bearer ${auth.user.access_token}`
      };
    }
  
    const apiClient = axios.create(apiConfig);
    return apiClient;
  }
};

export default getApiClient;
