import { Button, Tooltip } from "@mui/material";
import { ChangedDataFromOriginalActionButtonProps } from "./ChangedDataFromOriginalActionButtonProps";

const ChangedDataFromOriginalActionButton : React.FC<ChangedDataFromOriginalActionButtonProps> = ({buttonText, onButtonClick, toolltipText, variant}) => {

    return (<>
    <Tooltip title={toolltipText}>
            <Button
              onClick={onButtonClick}
              className="button"
              variant={variant}
            >
                {buttonText}
            </Button>
    </Tooltip>  
    </>)
}

export default ChangedDataFromOriginalActionButton