import { closestCorners, DndContext } from "@dnd-kit/core";
import AddQuizPlayerComponent from "../../QuizPlayer/Components/AddQuizPlayerComponent";
import { UNASSIGNED_TEAM_ID } from "../../Shared/Constants/UnassignedTeamId";
import { QuizTeamManagerProps } from "../Props/QuizTeamManagerProps";
import { Button, TextField } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useState } from "react";
import QuizTeam from "./QuizTeamCard";
import { QuizTeamWithQuizPlayers } from "../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers";

const QuizTeamManagerFullScreen: React.FC<QuizTeamManagerProps> = ({
  quizTeams,
  onQuizPlayerDeleteInAssignedTeam,
  onQuizPlayerDeleteInUnassignedTeam,
  moveQuizPlayerToTeam,
  onDeleteQuizTeam,
  onAddGuestQuizTeam,
  onAddClickQuizPlayerGuest,
  onAddClickQuizPlayerMultiSelect,
  onAddClickQuizPlayerWithEmail,
  quizUsersNotAttachedToQuiz,
}) => {
  const [guestQuizTeamName, setGuestQuizTeamName] = useState<string>();

  async function onAddGuestQuizTeamClick() {
    await onAddGuestQuizTeam(guestQuizTeamName);
    setGuestQuizTeamName("");
  }

  const handleDragEndQuizPlayer = async (event) => {
    const { active, over } = event;
    if (!over) return;
  
    const fromTeamIndex = quizTeams.findIndex((quizTeam) =>
      quizTeam.quizPlayers.some((quizPlayer) => quizPlayer.id === active.id)
    );
    const toTeamIndex = quizTeams.findIndex((quizTeam) => quizTeam.id === over.id);
  
    if (fromTeamIndex === toTeamIndex) return;
  
    const fromTeam = { ...quizTeams[fromTeamIndex] } as QuizTeamWithQuizPlayers;
    const toTeam = { ...quizTeams[toTeamIndex] } as QuizTeamWithQuizPlayers;
  
    await moveQuizPlayerToTeam(active.id, fromTeam.id, toTeam.id);
  };

  return (
    <div id="quiz-team-manager-container-fullscreen">
      <AddQuizPlayerComponent
        quizUsersNotAttachedToQuiz={quizUsersNotAttachedToQuiz}
        onAddClickQuizPlayerGuest={onAddClickQuizPlayerGuest}
        onAddClickQuizPlayerMultiSelect={onAddClickQuizPlayerMultiSelect}
        onAddClickQuizPlayerWithEmail={onAddClickQuizPlayerWithEmail}
      />

      <DndContext onDragEnd={handleDragEndQuizPlayer} collisionDetection={closestCorners}>
        <div id="unassigned-team-container">
          <QuizTeam
            quizTeam={quizTeams.find((quizTeam) => quizTeam.id === UNASSIGNED_TEAM_ID)}
            onQuizPlayerDelete={onQuizPlayerDeleteInUnassignedTeam}
            onQuizTeamDelete={async () => {}}
          />
        </div>

        <div id="quiz-teams-container">
          {quizTeams
            .filter((quizTeam) => quizTeam.id !== UNASSIGNED_TEAM_ID)
            .map((quizTeam) => (
              <QuizTeam
                key={quizTeam.id}
                quizTeam={quizTeam}
                onQuizPlayerDelete={onQuizPlayerDeleteInAssignedTeam}
                onQuizTeamDelete={onDeleteQuizTeam}
              />
            ))}
        </div>
      </DndContext>

      <div id="add-quiz-team-container">
        <div id="add-guest-quiz-team">
            <TextField
              label="Toevoegen gast team"
              variant="outlined"
              value={guestQuizTeamName}
              fullWidth
              onChange={(e) => setGuestQuizTeamName(e.target.value)}
            />
          <Button variant="contained" onClick={onAddGuestQuizTeamClick}>
            <GroupAddIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuizTeamManagerFullScreen;