import { Address } from "./Interface/Address";
import { Suggestion } from "./Interface/Suggestion";

export function GetAddressFromSuggestion(suggestion: Suggestion) {
    const addressSuggestion = {
        country: suggestion.address.country,
        countryISO: suggestion.address.country_code,
        province: suggestion.address.state,
        town: suggestion.address.city_district || suggestion.address.town || suggestion.address.city,
        postalcode: suggestion.address.postcode,
        street: suggestion.address.road,
        number: suggestion.address.house_number,
        latitude: suggestion.lat,
        longitude: suggestion.lon
    } as Address;

    return addressSuggestion;
}