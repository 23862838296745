import React from 'react';
import { PreviewListProps } from './Props/PreviewList';
import AttachmentPreview from './AttachmentPreview';

const PreviewList: React.FC<PreviewListProps>  = ({ attachments, onDeleteAttachment }) => {
  
  const deleteAttachment = (attachmentId: number) => {
    onDeleteAttachment(attachmentId);
  }

  return (
      <div className="AttachmentContainer">
        {attachments.map((attachment, index) => {
          return (
            <AttachmentPreview attachment={attachment} onDeleteAttachment={deleteAttachment}/>
          );
        })}
      </div>
  );
};

export default PreviewList;
