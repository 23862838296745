import { useNavigate } from "react-router-dom";
import ConfirmationMessage from "./ConfirmationMessage"
import { useEffect, useState } from "react";
import { MailRepo } from "../Repo/MailRepo";
import { ConfirmationInvitationToQuizProps } from "../Props/ConfirmationInvitationToQuizProps";

const InvitationToQuiz = ({confirmationKey, hasAccepted}: ConfirmationInvitationToQuizProps) => {
    const navigate = useNavigate();
    const mailRepo = new MailRepo(); 
    const [isProcessing, setIsProcessing] = useState<Boolean>(true);

    useEffect(() => {
        mailRepo.InvitationToQuiz(() => {
            setIsProcessing(false);
        },
        confirmationKey,
        hasAccepted)
    }, [confirmationKey]);

    if(isProcessing){
        return (
            <ConfirmationMessage
            title={`Uitnodiging ${hasAccepted ? "Accepteren" : "Afwijzen"}!`}
            message="Even geduld."
            buttonText={null}
            onButtonClick={null}
            />
        )
    }

    if(hasAccepted)
    {
        return (
            <ConfirmationMessage
            title="Uitnodiging Geaccepteerd!"
            message="Bedankt, je hebt de uitnodiging succesvol geaccepteerd."
            buttonText="Ga naar startpagina"
            onButtonClick={() => navigate(`/`)}
            />
        )
    }
    else
    {
        return (
            <ConfirmationMessage
            title="Uitnodiging Afgewezen!"
            message="Jammer dat je er niet bij kan zijn."
            buttonText="Ga naar startpagina"
            onButtonClick={() => navigate(`/`)}
            />
        )
    }
}

export default InvitationToQuiz