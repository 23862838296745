import React from 'react';
import ConfirmationMessage from './ConfirmationMessage';

const InvalidKeyComponent: React.FC = () => {
  return (
    <>
        <ConfirmationMessage
            title="Mail is ongeldig!"
            message="het lijkt erop dat De mail niet meer actief is."
            buttonText={null}
            onButtonClick={null}
        />
    </>
  );
};

export default InvalidKeyComponent;
