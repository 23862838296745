import { FC, useState } from 'react';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { Link } from "react-router-dom";
import './Header.scss'
import { useAuth } from 'react-oidc-context';
import { LogoutUser } from "../../Login/Authentication/AuthenticationRepo"
import logoImage from "../../../images/quizLabLogo.png"


const Header: FC = () => {
  const auth = useAuth();
  if (auth.isAuthenticated) {
    return <AuthenticatedHeader children={undefined}></AuthenticatedHeader>
  }
  else {
    return <UnAuthenticatedHeader></UnAuthenticatedHeader>
  }

}

const UnAuthenticatedHeader: FC = () => {
  return (
    <>
      <div className="siteHeader">
        <div className="siteHeader__section">
          <div className="siteHeader_logo">
            <img src={logoImage} alt="QuizLab Logo" className="siteHeader__logo-image" />
          </div>
          <Link to="/login" className='siteHeader__item'>Inloggen</Link>
        </div>
      </div>
    </>
  );
}


const AuthenticatedHeader: FC<{ children: any }> = ({ children }) => {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function onProfileNameClick(event: React.MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event?.currentTarget)
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  async function onLogoutClicked(){
    auth.removeUser();
    auth.signoutRedirect()
  }

  return (
    <>
      <div className="siteHeader">
        <div className="siteHeader__section">
          <Link to="/quiz" className='siteHeader__item'>Quizzes</Link>
          <Link to="/quiz" className='siteHeader__item'>Gebruikers beheer</Link>
        </div>
        <div className="siteHeader__section">
          <Link to={''}  onClick={(event: any) => onProfileNameClick(event)} className='siteHeader__item'>{auth.user?.profile.name}</Link>
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={closeMenu}>Mijn account</MenuItem>
        <MenuItem onClick={onLogoutClicked}>Uitloggen</MenuItem>
      </Menu>
    </>
  );
}

export default Header;