import React from 'react';
import { VolumeUp } from '@mui/icons-material';
import { AudioPreviewProps } from './Props/AudioPreviewProps';
import { AttachmentSource } from './Enum/AttachmentSource';

const AudioPreview: React.FC<AudioPreviewProps> = ({ attachment, isThumbnail }) => {

  if (isThumbnail) {
    return <div className="audio-thumbnail"><VolumeUp fontSize="inherit" /></div>;
  }

  if(attachment.source == AttachmentSource.GoogleDrive){
    return (<>
    <iframe src={`https://drive.google.com/file/d/${attachment.fileId}/preview`}
        width="430"
        height="80"
        allow="autoplay">
     </iframe>
    </>)
  }

  return (
  <>
    <audio src={attachment.url}></audio>
    </>
  );
};

export default AudioPreview;
