import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import DragAndDropUploader from './DragAndDropUploader';
import { FileUploaderProps } from './Props/FileUploaderProps';
import PreviewList from './PreviewList';
import { FileUploadProgressStatus } from './interface/FileUploadProgressStatus';
import UploadProgress from './UploadProgress';
import YouTubeInput from './YouTubeInput';
import { AttachmentSource } from './Enum/AttachmentSource';
import { FileUploadStatus } from './Enum/FileUploadStatus';
import { FileUploadError } from './Enum/FileUploadError';
import './FileUploader.scss';
import SnackbarNotification from '../../Snackbar/SnackbarNotifcation';

const FileUploader: React.FC<FileUploaderProps> = ({
  showDragAndDrop,
  showPreview,
  showYouTubeInput,
  attachments,
  maxAttachments,
  acceptedTypes,
  onFileUploaded,
  onAddUrl,
  onDeleteAttachment
}) => {
  const [fileUploadStatusses, setFileUploadStatusses] = useState<FileUploadProgressStatus[]>([])
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const validateAttachmentLimit = (newFiles: number): boolean => {
    const remainingSlots = maxAttachments - attachments.length;
    if (newFiles > remainingSlots) {
        setSnackbarMessage(`Bestand limit bereikt voor deze vraag.`);
        setSnackbarOpen(true);
        return false;
    }
    return true;
  };

  const validateFileTypes = (file: File):Boolean => {
    const isValidType = acceptedTypes.includes(file.type);
    return isValidType;
};

  const handleDeleteAttachment = (attachmentId: number) => {
    onDeleteAttachment(attachmentId);
  }

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []) as File[];
    await uploadFilesToServer(newFiles);
  };

  const handleDrop = async (droppedFiles: File[]) => {
    await uploadFilesToServer(droppedFiles);
  };

  const uploadFilesToServer = async (filesToUpload: File[]) => {
        if (!validateAttachmentLimit(filesToUpload.length)) {
          return;
      }

      const validFiles: File[] = [];

      filesToUpload.forEach(file => {
          if (validateFileTypes(file)) {
              validFiles.push(file);
          } else {
            const fileUploadStatus = {
              Error: FileUploadError.InvalidFormat,
              File: file,
              FileUploadStatus: FileUploadStatus.Fail
              
            } as FileUploadProgressStatus
            setFileUploadStatusses([fileUploadStatus, ...fileUploadStatusses])
          }
      });

      if (validFiles.length === 0) {
          return;
      }
  
    const fileUploads = validFiles.map((file) => {
      const fileUploadProgressStatus = {
        FileUploadStatus: FileUploadStatus.InProgress,
        File: file,
        Error: null,
      } as FileUploadProgressStatus;
  
      setFileUploadStatusses((prevStatuses) => [...prevStatuses, fileUploadProgressStatus]);
  
      return onFileUploaded(file).then((status) => {
        setFileUploadStatusses((prevStatuses) =>
          prevStatuses.map((statusObj) =>
            statusObj.File === file
              ? {
                  ...statusObj,
                  FileUploadStatus: status,
                  Error: status === FileUploadStatus.Fail ? FileUploadError.General : null,
                }
              : statusObj
          )
        );
      });
    });

    await Promise.all(fileUploads);
  };
  
  
  const onYouTubeUrlAdd = async (url: string) => {

    if(validateAttachmentLimit(1)){
      onAddUrl(url, AttachmentSource.YouTube);
    }
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box className='FileUploader-container'>
      {showDragAndDrop ? (
        <DragAndDropUploader onDrop={handleDrop} acceptedTypes={acceptedTypes} />
      ) : (
        <Button variant="contained" component="label" startIcon={<UploadFile />}>
          Bestanden uploaden
          <input type="file" hidden multiple onChange={handleFileUpload} />
        </Button>
      )}

      {showYouTubeInput && <YouTubeInput onAddUrl={onYouTubeUrlAdd}/>}

      {<UploadProgress uploadProgressFiles={fileUploadStatusses}/>}

      {showPreview && <PreviewList attachments={attachments} onDeleteAttachment={handleDeleteAttachment}/>}

      <SnackbarNotification verticalLocation='top' horizontalLocation='center' severity='error' textToDisplay={snackbarMessage}
                      shouldOpenSnackbar={snackbarOpen} setShouldOpenSnackbar={setSnackbarOpen}/>
    </Box>
  );
};

export default FileUploader;
