import React, { useEffect, useState } from 'react';
import { Card, CardContent, TextField, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { QuestionsFromRound } from '../../../../interfaces/Quiz/QuestionsFromRound';
import { Quiz } from '../../../../interfaces/Quiz/Quiz';
import { Round } from '../../../../interfaces/Quiz/Round';
import { UpdateQuestion } from '../../../../interfaces/Questions/UpdateQuestion';
import { useAuth } from 'react-oidc-context';
import { QuizRepo } from '../../../Quiz/Repo/QuizRepo';
import DeleteModal from '../../../Shared/Delete/DeleteModal';
import { FileUploadStatus } from '../../../Shared/Components/FileUpload/Enum/FileUploadStatus';
import { AttachmentSource } from '../../../Shared/Components/FileUpload/Enum/AttachmentSource';
import FileUploader from '../../../Shared/Components/FileUpload/FileUploader';
import Answers from '../../../Answer/Components/Answers';

interface SortableQuestionProps {
  initialQuestion: QuestionsFromRound;
  onDeleteQuestion: (questionId: number) => void;
  quiz: Quiz;
  descriptionRef: React.RefObject<HTMLInputElement | null>;
  selectedRound: Round;
  onQuestionChange: (updatedQuestion: UpdateQuestion) => void;
  onAddAnswer: (questionId: number) => void;
  onUpdateAnswer: (questionId: number, descriptionId: string, answerId: number) => void;
  onDeleteAnswer: (answerId: number, questionId: number) => void;
  onAddUrlAttachment: (url: string, source: AttachmentSource, questionId: number) => void;
  onDeleteAttachment: (attachmentId: number, questionId: number) => void;
  onFileAttachmentUpload: (file: File, questionId: number) => Promise<FileUploadStatus>;
}

function SortableQuestionDesktop({
  initialQuestion,
  onDeleteQuestion,
  quiz,
  descriptionRef,
  selectedRound,
  onQuestionChange, 
  onAddUrlAttachment,
  onDeleteAttachment,
  onFileAttachmentUpload,
  onAddAnswer,
  onDeleteAnswer,
  onUpdateAnswer
}: SortableQuestionProps) {
  const auth = useAuth();
  const quizRepo = new QuizRepo(auth);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [question, setQuestion] = useState<QuestionsFromRound>(initialQuestion);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: question.id,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    }
  });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  useEffect(() => {
    setQuestion(initialQuestion);
  }, [quiz]);


  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDescription = event.target.value;
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      description: newDescription,
    }));
  };

  const onBlurQuestionDescription = () => {
    handleSaveChanges();
  };

  const handleSaveChanges = async () => {
    const updateQuestion: UpdateQuestion = {
      id: question.id,
      description: question.description ?? "",
      sequence: question.sequence ?? 0
    };
    try {
      await quizRepo.UpdateQuestion(() => { onQuestionChange(updateQuestion); }, updateQuestion);
    } catch (error) {
      console.error('Error saving question:', error);
    }
  };

  const handleOnMouseEnter = () => {
    setIsHovered(true);
  };

  const handleOnMouseLeave = () => {
    setIsHovered(false);
  };

  const handleFileUpload = async (file: File): Promise<FileUploadStatus> => {
     return await onFileAttachmentUpload(file, question.id);
};

  const handleDeleteAttachment = async (attachmentId: number) => {
    onDeleteAttachment(attachmentId, question.id);
  }

  const handleAddUrlAttachment = async (url: string, source: AttachmentSource) =>
  {
    onAddUrlAttachment(url, source, question.id);
  }

  const handleOnDeleteAnswer = (answerId: number) => {
      onDeleteAnswer(answerId, question.id);
  }

  const handleOnUpdateAnswer = (answerId: number, description: string) => {
      onUpdateAnswer(question.id, description, answerId);
  }

  const handleOnAddAnswer = (): void => {
    onAddAnswer(question.id);
  }

  return (
    <>
      <div
          className="question-list-element"
          ref={setNodeRef}
          style={styles}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
        <Card variant="outlined" className="question-card">
          <CardContent className='question-card-content'>
            <div className="question-header">
              <div className="question-container-left">
                {question.sequence}
              </div>
              <div className="question-container-right">
                <IconButton
                  className={`question-container-button ${isHovered ? '' : "question-container-button-hidden"}`}
                  aria-label="Delete"
                  onClick={() => setShowConfirmationModal(true)}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  className="round-container-drag"
                  color="primary"
                  aria-label="Drag"
                  {...attributes}
                  {...listeners}
                >
                  <DragIndicatorIcon />
                </IconButton>
              </div>
            </div>

            <TextField
                className='question-input-element'
                label="Vraag"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                value={question.description}
                onChange={handleDescriptionChange}
                onBlur={onBlurQuestionDescription}
                inputRef={descriptionRef}
              />

              <Answers answers={question.answers} 
                onAddNewAnswer={handleOnAddAnswer} 
                onDeleteAnswer={handleOnDeleteAnswer}
                onUpdateAnswer={handleOnUpdateAnswer}/>

                <FileUploader
                    showDragAndDrop={true}
                    showPreview={true}
                    showYouTubeInput={true}
                    attachments={question.attachments}
                    maxAttachments={3}
                    acceptedTypes={['image/jpeg', 'image/png', 'video/mp4', 'audio/mpeg', 'video/youtube']}
                    onFileUploaded={handleFileUpload}
                    onAddUrl={handleAddUrlAttachment}
                    onDeleteAttachment={handleDeleteAttachment}
                  />
          </CardContent>
        </Card>
      </div>

      <DeleteModal
        deleteCallback={() => onDeleteQuestion(question.id)}
        setOpenModal={setShowConfirmationModal}
        openModal={showConfirmationModal}
        subject='Vraag'
        subjectValue={question.description}/>
    </>
  );
}

export default SortableQuestionDesktop;
