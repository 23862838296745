import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { LeaderboardShowOptions } from "../Enum/LeaderBoardShowOptions";
import { LeaderboardOptionsDialogProps } from "../Props/LeaderboardOptionsDialogProps";
import "../Style/LeaderboardOptionsDialog.scss";
import LeaderboardDialog from "./LeaderBoardDialog";

const LeaderboardOptionsDialog: React.FC<LeaderboardOptionsDialogProps> = ({
  open,
  onClose,
  leaderBoard,
  totalPointsQuiz
}) => {
  const [fullScreenOpen, setFullScreenOpen] = useState<boolean>(false);
  const [showOption, setShowOption] = useState<LeaderboardShowOptions>(LeaderboardShowOptions.All)

  const handleShowLeaderboard = () => {
    setShowOption(LeaderboardShowOptions.All);
    setFullScreenOpen(true); 
    onClose(); 
  };

  const handleShowLeaderboardStep = () => {
    setShowOption(LeaderboardShowOptions.StepByStep);
    setFullScreenOpen(true);
    onClose(); 
  };

  const handleFullScreenClose = () => {
    setFullScreenOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Leaderboard opties</DialogTitle>
        <DialogContent>
          <Typography>Kies hoe je het leaderboard wilt tonen.</Typography>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Tooltip title="alles">
            <Button
              onClick={handleShowLeaderboard}
              variant="outlined"
              className="button"
            >
              <EmojiEventsIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Stap voor stap">
            <Button
              onClick={handleShowLeaderboardStep}
              variant="outlined"
              className="button"
            >
              <MoreHorizIcon />
              <ArrowRightAltIcon />
              <EmojiEventsIcon />
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>

      <LeaderboardDialog totalPointsQuiz={totalPointsQuiz} fullLeaderBoard={leaderBoard} 
            onLeaderBoardDialogClose={handleFullScreenClose} fullScreenOpen={fullScreenOpen} 
            showOption={showOption}/>
    </>
  );
};

export default LeaderboardOptionsDialog;
