import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import '../Style/SendEmailQuizPlayers.scss'
import { SendEmailQuizPlayersModalProps } from '../Interfaces/SendEmailQuizPlayersModalProps';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from 'react-oidc-context';

const SendEmailQuizPlayersModal: React.FC<SendEmailQuizPlayersModalProps> = ({ open, onClose, quizPlayers, handleSendMail }) => {
  const [selectedPlayers, setSelectedPlayers] = useState<number[]>([]);
  const auth = useAuth();

  useEffect(() => {
    const initialSelectedPlayers = quizPlayers
      .filter(quizPlayer => !quizPlayer.isInvited)
      .map(quizPlayer => quizPlayer.id);
    setSelectedPlayers(initialSelectedPlayers)
  }, [auth, quizPlayers]);

  const handleSendEmail = () => {
    handleSendMail(selectedPlayers);
  };

  const handleCheckboxChange = (quizPlayerId) => {
    setSelectedPlayers(prevSelected =>
      prevSelected.includes(quizPlayerId)
        ? prevSelected.filter(id => id !== quizPlayerId)
        : [...prevSelected, quizPlayerId]
    );
  };

  const handleClose = () => {
    onClose();
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Spelers uitnodigen</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="quiz-player-list-container">
          <ul className="player-list">
            {quizPlayers.map(quizPlayer => (
              <li key={quizPlayer.id} className="player-item">
                <input
                  type="checkbox"
                  id={`player-${quizPlayer.id}`}
                  checked={selectedPlayers.includes(quizPlayer.id)}
                  onChange={() => handleCheckboxChange(quizPlayer.id)}
                />
                <label htmlFor={`player-${quizPlayer.id}`} className="player-name">
                  {quizPlayer.quizPlayerName}
                </label>
                <div className='player-email'>{quizPlayer.userMail || quizPlayer.invitationMail}</div>
              </li>
            ))}
          </ul>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSendEmail}>Verstuur email</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendEmailQuizPlayersModal;