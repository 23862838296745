import { Quiz } from "../../../interfaces/Quiz/Quiz";
import { TeamRoundPoint } from "../../../interfaces/Quiz/TeamRoundPoint";
import { QuizPlayer } from "../../../interfaces/QuizPlayer/QuizPlayer";
import { QuizTeamWithQuizPlayers } from "../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers";
import { QuizUser } from "../../../interfaces/QuizUser/QuizUser";
import { UNASSIGNED_TEAM_ID } from "../../Shared/Constants/UnassignedTeamId";

function getQuizTeamAndPlayerDetails(
    quiz: Quiz,
    quizTeamId: number,
    quizPlayerId: number
): {
    quizTeam: QuizTeamWithQuizPlayers;
    quizTeamIndex: number;
    quizPlayer: QuizPlayer;
    quizPlayerIndex: number;
} {
    const quizTeamIndex = quiz.quizTeams.findIndex(quizTeam => quizTeam.id === quizTeamId);
    if (quizTeamIndex === -1) {
        throw new Error(`Quiz team with ID ${quizTeamId} not found.`);
    }

    const quizTeam = quiz.quizTeams[quizTeamIndex];

    const quizPlayerIndex = quizTeam.quizPlayers.findIndex(player => player.id === quizPlayerId);
    if (quizPlayerIndex === -1) {
        throw new Error(`Quiz player with ID ${quizPlayerId} not found in team ${quizTeamId}.`);
    }

    const quizPlayer = quizTeam.quizPlayers[quizPlayerIndex];

    return {
        quizTeam,
        quizTeamIndex,
        quizPlayer,
        quizPlayerIndex
    };
}

export function updateQuizSetQuizPlayerToUnattached(
    quiz: Quiz, 
    quizTeamId: number, 
    quizPlayerId: number
): Quiz {
    const { quizTeam, quizTeamIndex, quizPlayerIndex } = 
        getQuizTeamAndPlayerDetails(quiz, quizTeamId, quizPlayerId);

    const [removedPlayer] = quizTeam.quizPlayers.splice(quizPlayerIndex, 1);
    if (!removedPlayer.userId) {
        throw new Error(`Quiz player with name ${removedPlayer.quizPlayerName} has no userId.`);
    }

    const updatedUnattachedUsers: QuizUser[] = [
        ...quiz.quizUsersNotAttachedToQuiz,
        {
            id: removedPlayer.userId,
            email: removedPlayer.userMail,
            userName: removedPlayer.quizPlayerName 
        }
    ];

    const updatedQuizTeams = [...quiz.quizTeams];
    updatedQuizTeams[quizTeamIndex] = {
        ...quizTeam,
        quizPlayers: quizTeam.quizPlayers.filter(quizPlayer => quizPlayer.id !== quizPlayerId)
    };

    const updatedQuiz: Quiz = {
        ...quiz,
        quizTeams: updatedQuizTeams,
        quizUsersNotAttachedToQuiz: updatedUnattachedUsers
    };

    return updatedQuiz;
}

export function updateQuizRemoveQuizPlayerFromQuiz(quiz: Quiz, quizTeamId: number, quizPlayerId: number): Quiz {
    const { quizTeam, quizTeamIndex, quizPlayerIndex } = 
        getQuizTeamAndPlayerDetails(quiz, quizTeamId, quizPlayerId);

    const updatedQuizPlayers = quizTeam.quizPlayers.filter((_, index) => index !== quizPlayerIndex);

    const updatedQuizTeams = [...quiz.quizTeams];
    updatedQuizTeams[quizTeamIndex] = {
        ...quizTeam,
        quizPlayers: updatedQuizPlayers
    };

    const updatedQuiz: Quiz = {
        ...quiz,
        quizTeams: updatedQuizTeams
    };

    return updatedQuiz;
}

export function updateQuizWithNewQuizPlayers(quiz: Quiz, prevQuizTeams: QuizTeamWithQuizPlayers[], fromQuizTeamIndex: number, toQuizTeamIndex: number, quizPlayerId: number, movedQuizPlayer: QuizPlayer, quizPlayerIndex: number) {

    if (
        fromQuizTeamIndex == UNASSIGNED_TEAM_ID ||
        toQuizTeamIndex == UNASSIGNED_TEAM_ID ||
        quizPlayerIndex == UNASSIGNED_TEAM_ID ||
        !movedQuizPlayer
      ) {
        return quiz;
      } 

    const updatedQuizTeams = [...prevQuizTeams];

    updatedQuizTeams[toQuizTeamIndex] = {
        ...updatedQuizTeams[toQuizTeamIndex],
        quizPlayers: [...updatedQuizTeams[toQuizTeamIndex].quizPlayers, movedQuizPlayer],
    };

    updatedQuizTeams[fromQuizTeamIndex] = {
        ...updatedQuizTeams[fromQuizTeamIndex],
        quizPlayers: updatedQuizTeams[fromQuizTeamIndex].quizPlayers.filter((quizPlayer) => quizPlayer.id != quizPlayerId),
    };

    const updatedQuiz: Quiz = {
        ...quiz,
        quizTeams: updatedQuizTeams
    }

    return updatedQuiz;
}

export function updateQuizWithNewlyAddedQuizUsersToUnassignedTeam(quiz: Quiz, quizTeamIndex: number, quizPlayers: QuizPlayer[], quizUsersNotAttachedToQuiz: QuizUser[]) {

    const updatedTeam = {
        ...quiz.quizTeams[quizTeamIndex],
        quizPlayers: [...quiz.quizTeams[quizTeamIndex].quizPlayers, ...quizPlayers],
    } as QuizTeamWithQuizPlayers;

    const updatedQuizTeams = [...quiz.quizTeams];
    updatedQuizTeams[quizTeamIndex] = updatedTeam;
    const updatedQuizPlayresNotAttachedToQuiz = [...quizUsersNotAttachedToQuiz];

    const updatedQuiz: Quiz = {
        ...quiz,
        quizUsersNotAttachedToQuiz: updatedQuizPlayresNotAttachedToQuiz,
        quizTeams: updatedQuizTeams

    }
    return updatedQuiz;
}

export function updateQuizWithQuizTeamAndUnassingedQuizPlayer(quiz: Quiz, quizTeamIndex: number, unassignedTeamId: number, playerQuizId: number) {
    let quizTeams: QuizTeamWithQuizPlayers[] = [...quiz.quizTeams];

    if (quizTeamIndex !== unassignedTeamId) {
        const updatedQuizTeam = {
            ...quiz.quizTeams[quizTeamIndex],
            quizPlayers: quiz.quizTeams[quizTeamIndex].quizPlayers.filter((quizPlayer) => quizPlayer.id !== playerQuizId),
        };

        let unAssignedTeam = quizTeams.find(x => x.id === unassignedTeamId);
        let playerThatNeedsToBeInAUnassignedTeam = quiz.quizTeams[quizTeamIndex].quizPlayers.find((quizPlayer) => quizPlayer.id === playerQuizId);
        let quizTeamUnassignedIndex = quizTeams.findIndex(x => x.id === unassignedTeamId);

        if (unAssignedTeam !== undefined && playerThatNeedsToBeInAUnassignedTeam !== undefined && quizTeamUnassignedIndex >= 0) {
            unAssignedTeam.quizPlayers.push(playerThatNeedsToBeInAUnassignedTeam);
        }

        quizTeams[quizTeamIndex] = updatedQuizTeam;
        quizTeams[quizTeamUnassignedIndex] = unAssignedTeam;
    }

    let updatedQuiz: Quiz = {
        ...quiz,
        quizTeams: quizTeams
    }

    return updatedQuiz;
}

export function updateQuizWithRemovedExternalPlayerFromUnassingedTeam(quiz: Quiz, quizTeamIndex: number, playerQuizId: number) {
    let quizTeams: QuizTeamWithQuizPlayers[] = [...quiz.quizTeams];

    const updatedQuizTeam = {
        ...quiz.quizTeams[quizTeamIndex],
        quizPlayers: quiz.quizTeams[quizTeamIndex]
                            .quizPlayers.filter((quizPlayer) => quizPlayer.id !== playerQuizId),
    };

    quizTeams[quizTeamIndex] = updatedQuizTeam;

    let updatedQuiz: Quiz = {
        ...quiz,
        quizTeams: quizTeams,
    }

    return updatedQuiz;
}

export function updateQuizWithRemovedQuizPlayerFromUnAssingedQuizTeam(quiz: Quiz, quizTeamIndex: number, playerQuizId: number) {
    let quizTeams: QuizTeamWithQuizPlayers[] = [...quiz.quizTeams];

    const updatedQuizTeam = {
        ...quiz.quizTeams[quizTeamIndex],
        quizPlayers: quiz.quizTeams[quizTeamIndex].quizPlayers.filter((quizPlayer) => quizPlayer.id !== playerQuizId),
    };

    let playerThatNeedsToBeUnAttachedFromQuiz = quiz.quizTeams[quizTeamIndex].quizPlayers.find((quizPlayer) => quizPlayer.id === playerQuizId);
    let quizUser: QuizUser = {
        email: playerThatNeedsToBeUnAttachedFromQuiz.invitationMail,
        userName: playerThatNeedsToBeUnAttachedFromQuiz.quizPlayerName,
        id: playerThatNeedsToBeUnAttachedFromQuiz.userId,
    }
    quizTeams[quizTeamIndex] = updatedQuizTeam;
    let updatedQuizUsersThatAreUnAttached = [...quiz.quizUsersNotAttachedToQuiz, quizUser]

    let updatedQuiz: Quiz = {
        ...quiz,
        quizTeams: quizTeams,
        quizUsersNotAttachedToQuiz: updatedQuizUsersThatAreUnAttached
    }

    return updatedQuiz;
}

export function updateQuizWithAddedPlayerToUnassignedTeam(quiz: Quiz, quizteamIndex: number, quizPlayer: QuizPlayer) {

    const updatedTeam = {
        ...quiz.quizTeams[quizteamIndex],
        quizPlayers: [...quiz.quizTeams[quizteamIndex].quizPlayers, quizPlayer],
    };

    const updatedQuizTeams = [...quiz.quizTeams];
    updatedQuizTeams[quizteamIndex] = updatedTeam;

    let updatedQuiz: Quiz = {
        ...quiz,
        quizTeams: updatedQuizTeams
    };

    return updatedQuiz;
}

export function updateQuizWithAddedPlayersToUnassignedTeam(quiz: Quiz, quizteamIndex: number, quizPlayers: QuizPlayer[]) {

    const updatedTeam = {
        ...quiz.quizTeams[quizteamIndex],
        quizPlayers: [...quiz.quizTeams[quizteamIndex].quizPlayers, ...quizPlayers],
    } as QuizTeamWithQuizPlayers;

    const updatedQuizTeams = [...quiz.quizTeams];
    updatedQuizTeams[quizteamIndex] = updatedTeam;

    let updatedQuiz: Quiz = {
        ...quiz,
        quizTeams: updatedQuizTeams
    };

    return updatedQuiz;
}

export function updateQuizWihtChangedQuizTeam(quiz: Quiz, newQuizTeamName: string, quizTeamIndex: number) {
    const quizTeamToChange = quiz.quizTeams[quizTeamIndex];
    quizTeamToChange.name = newQuizTeamName;

    const quizTeamsWithChangedTeamName = [...quiz.quizTeams];
    quizTeamsWithChangedTeamName.splice(quizTeamIndex, 1, quizTeamToChange)

    let updatedQuiz: Quiz = {
        ...quiz,
        quizTeams: [...quizTeamsWithChangedTeamName]
    }
    return updatedQuiz;
}

export function updateQuizWithADeletedQuizTeam(quiz: Quiz, quizTeamToDelete: QuizTeamWithQuizPlayers): Quiz {
    let quizTeamIndex = quiz.quizTeams.findIndex(x => x.id === quizTeamToDelete.id);
    let unAssignedTeam = quiz.quizTeams.find(x => x.id == UNASSIGNED_TEAM_ID);
    if (quizTeamIndex === -1 || unAssignedTeam === undefined) return quiz;
   
    let updatedQuizTeams = quiz.quizTeams.filter(x => x.id !== quizTeamToDelete.id);

    let updatedUnAssignedTeam = {
        ...unAssignedTeam,
        quizPlayers: [...unAssignedTeam.quizPlayers, ...quizTeamToDelete.quizPlayers]
    };

    updatedQuizTeams = updatedQuizTeams.map(team => team.id === UNASSIGNED_TEAM_ID ? updatedUnAssignedTeam : team);

    let updatedQuiz: Quiz = {
        ...quiz,
        quizTeams: updatedQuizTeams
    };
    return updatedQuiz;
}

export function updateQuizWithNewQuizTeam(quiz: Quiz, newQuizTeam: QuizTeamWithQuizPlayers) {

    let newTeamRoundPoints: TeamRoundPoint[] = quiz.rounds.map(round => {
        let newTeamRoundPoint = { roundId: round.id,
                quizTeamId: newQuizTeam.id,
                 points: "0" } as TeamRoundPoint;

        return newTeamRoundPoint;
    })

    let updatedQuiz: Quiz = {
        ...quiz,
        quizTeams: [...quiz.quizTeams, newQuizTeam],
        teamRoundPoints: [...quiz.teamRoundPoints, ...newTeamRoundPoints]
    }   
    return updatedQuiz;
}
