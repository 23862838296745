import { FC } from 'react';
import "../../Shared/Misc/GeneralStyling.scss";
import "../../Shared/Header/Header.scss";
import "../../Shared/Form/Formlayout.scss";
import Header from '../../Shared/Header/Header';
import EditQuiz from '../Components/EditQuiz';
import { useParams } from 'react-router-dom';
import "../Style/QuizData.scss";

const EditQuizView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const quizId = id ? parseInt(id, 10) : 0;

  return (
    <>
      <Header></Header>
      <main className='quizContainer'>
        <EditQuiz
          quizId={quizId}
        />
      </main>
    </>
  );
};

export default EditQuizView;
