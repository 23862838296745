import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { TeamRoundPoint } from "../../../../interfaces/Quiz/TeamRoundPoint";
import { ScoreInputFieldProps } from "../../Props/Scores/ScoreInputFieldProps";

export const ScoreInputField: React.FC<ScoreInputFieldProps> = ({
    teamRoundPoint,
    maxRoundPoint,
    changeTeamRoundPointState,
    saveTeamRoundPoint
}) => {
    return (
        <TextField
            className="scoreInputField"
            type="text"
            value={String(teamRoundPoint.points).replace(".",",")}
            InputProps={{
                endAdornment: (
                    <InputAdornment disableTypography position="end">
                        / {maxRoundPoint}
                    </InputAdornment>
                ),
                inputProps: {
                    min: 0,
                },
            }}
            onChange={(e) =>
                changeTeamRoundPointState({
                    quizTeamId: teamRoundPoint.quizTeamId, 
                    points: e.target.value, 
                    roundId: teamRoundPoint.roundId } as TeamRoundPoint)
            }
            onBlur={(e) =>
                saveTeamRoundPoint({
                    quizTeamId: teamRoundPoint.quizTeamId, 
                    points: e.target.value, 
                    roundId: teamRoundPoint.roundId } as TeamRoundPoint)
            }
            onFocus={(e) => {
                const input = e.target as HTMLInputElement;
                if (input.selectionStart === input.selectionEnd) {
                    // If the user navigated using Tab, select all text
                    input.select();
                }
            }}
        />
    );
};
