import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { QuizPlayerCardProps } from "../Props/QuizPlayerCardProps";
import { getPlayerRegisterdClass, getPlayerStatusClass } from "../Shared/QuizPlayerHelper";

export const NonDraggableQuizPlayerCard: React.FC<QuizPlayerCardProps> = ({ quizPlayer, onPlayerDelete }) => {

  function handleOnDeleteButtonClick() {
    onPlayerDelete(quizPlayer);
  }

  const className = `quiz-player-card ${getPlayerRegisterdClass(quizPlayer)} ${getPlayerStatusClass(quizPlayer)}`;

  return (
    <div className={className}>
      <div className="quiz-player-avatar">
        {quizPlayer.initials}
      </div>

      <span className="quiz-player-name">
        {quizPlayer.quizPlayerName}
      </span>

      <div className="quiz-player-hover-controls">
        <IconButton aria-label="Delete" onClick={handleOnDeleteButtonClick}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};
