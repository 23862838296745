import { Quiz } from "../../../interfaces/Quiz/Quiz";
import { Round } from "../../../interfaces/Quiz/Round";
import { TeamRoundPoint } from "../../../interfaces/Quiz/TeamRoundPoint";
import { CreateOrEditRound } from "../../../interfaces/Round/CreateOrEditRound";
import { UNASSIGNED_TEAM_ID } from "../../Shared/Constants/UnassignedTeamId";

export function UpdateQuizWithNewRound(quiz: Quiz, newRound: Round): Quiz {
  let copyOfRounds = [...quiz.rounds];
  copyOfRounds.push(newRound);

  let newTeamRoundPoints: TeamRoundPoint[] = quiz.quizTeams
    .filter(quizTeam => quizTeam.id !== UNASSIGNED_TEAM_ID)
    .map(quizTeam => {
    let newTeamRoundPoint = { roundId: newRound.id,
            quizTeamId: quizTeam.id,
             points: "0" } as TeamRoundPoint;

    return newTeamRoundPoint;
  })

  const updatedQuiz: Quiz = {
    ...quiz,
    rounds: copyOfRounds,
    teamRoundPoints: [...quiz.teamRoundPoints, ...newTeamRoundPoints]    
  };
  
  return updatedQuiz;
}

export function UpdateQuizWithUpdatedRound(quiz: Quiz, updateRound: CreateOrEditRound): Quiz {
  let updatedRounds = quiz.rounds.map(round => {
    if (round.id === updateRound.roundId) return {...round, maxPoints: updateRound.maxPoints, name: updateRound.roundName};
    else return round;
  });
  
  const updatedQuiz: Quiz = {
    ...quiz,
    rounds: updatedRounds
  };
  return updatedQuiz;
}

export function UpdateQuizWithDeleteRound(quiz: Quiz, deletedRound: Round): Quiz {
  let roundsThatStillExist = quiz.rounds.filter(x => x.id !== deletedRound.id);
  let copyOfRounds = [...roundsThatStillExist];

  copyOfRounds.forEach((round:Round, index:number) => {
    round.sequence = index + 1 ;
  });

  const updatedQuiz:Quiz = {
    ...quiz,
    rounds : copyOfRounds
  }

  return updatedQuiz;
}

export function CopyQuizWithCopiesOfRounds(quiz:Quiz, rounds:Round[]){
  let copyOfRounds = [...rounds];
  const updatedQuiz: Quiz = {
      ...quiz,
      rounds: copyOfRounds
    };
  return updatedQuiz;
}