import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ChangedDataFromOriginalDialogProps } from "../Props/ChangedDataFromOriginalDialogProps"
import ChangedDataList from "./ChangedDataList";
import "../DataChanged.scss"
import ChangedDataFromOriginalActionButton from "../../../Button/ChangedDataFromOriginalActionButton";

const ChangedDataDialog: React.FC<ChangedDataFromOriginalDialogProps> = ({open, title, changedDataItems, buttonActionsInfo}) => {

    return (<>
    <Dialog open={open} className="changeddata-dialog">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <ChangedDataList changedDataItems={changedDataItems}/>
        </DialogContent>
        <DialogActions>
            {
                buttonActionsInfo.map((item, index) => (
                    <>
                        <ChangedDataFromOriginalActionButton buttonText={item.buttonText}
                                      onButtonClick={item.onButtonClick} 
                                     toolltipText={item.toolltipText}/>
                    </>
                ))
            }
        </DialogActions>
      </Dialog>
    </>)
}

export default ChangedDataDialog;