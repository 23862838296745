import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";
import { LeaderboardDialogProps } from "../Props/LeaderBoardDialogProps";
import Leaderboard from "./LeaderBoard";


const LeaderboardDialog: React.FC<LeaderboardDialogProps> = ({ fullLeaderBoard, totalPointsQuiz, showOption, fullScreenOpen, onLeaderBoardDialogClose }: LeaderboardDialogProps) =>
{
    const handleFullScreenClose = () => {
        onLeaderBoardDialogClose();
    };

    return (<>
        <Dialog
        open={fullScreenOpen}
        onClose={handleFullScreenClose}
        fullScreen
      >
        <DialogTitle className="dialog-title">
          Leaderboard
          <IconButton
            aria-label="close"
            onClick={handleFullScreenClose}
            className="close-button"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Leaderboard fullLeaderBoard={fullLeaderBoard} totalPointsQuiz={totalPointsQuiz} ShowOptions={showOption} />
        </DialogContent>
      </Dialog>
    </>)
}

export default LeaderboardDialog